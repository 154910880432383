import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { McCleeryLogo } from 'components/Icons';
import { useResetMutation } from 'app/services';
import qs from 'query-string';

// ResetPassword component definition
export const ResetPassword = () => {
  // State and mutation hooks
  const [resetPassword, { data: isPasswordChanged, isSuccess, isError }] = useResetMutation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // React Router hooks
  const { search } = useLocation();
  const { push } = useHistory();

  // Extract token from query string using useMemo
  const tokenId = useMemo(() => qs.parse(search).token as string, [search]);

  // Function to handle form submission
  const onFinish = async (values: { newPassword: string; confirmNewPassword: string }) => {
    await resetPassword({ ...values, tokenId }).unwrap();
  };

  // Effect to handle success and error notifications
  useEffect(() => {
    if (isPasswordChanged && isSuccess) {
      // Redirect to login page after successful password reset
      push({ pathname: '/login' });
    }
    if (!isPasswordChanged && isError) {
      // Display error notification if password reset fails
      notification.error({
        placement: 'topRight',
        message: 'Something went wrong while changing your password. Try again later.',
      });
    }
  }, [isError, isPasswordChanged, isSuccess, push]);

  // Component JSX
  return (
    <div className='authentication-container'>
      <img src="/login-bg.jpeg" alt="Background" className="bg-img" />
      <div className="authentication-form">
        <div className="authentication-form-container">
          {/* McCleery logo */}
          <div className="authentication-form-img">
            <McCleeryLogo />
          </div>
          {/* Title */}
          <h2 className="authentication-form-title">Reset your password</h2>

          {/* Ant Design Form */}
          <Form
            name="authentication"
            className="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            {/* New Password input */}
            <Form.Item
              name="newPassword"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password autoComplete="on" />
            </Form.Item>

            {/* Confirm Password input */}
            <Form.Item
              name="confirmNewPassword"
              label="Confirm Password"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      setIsButtonDisabled(false);
                      return Promise.resolve();
                    }
                    setIsButtonDisabled(true);
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="on" />
            </Form.Item>

            {/* Submit button */}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isButtonDisabled}>
                Continue
              </Button>
            </Form.Item>
          </Form>

          {/* Link to return to sign in */}
          <div className="return-to-sign-in">
            <Link to="/login">Return to sign in</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
