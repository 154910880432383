import { DownloadOutlined } from "@ant-design/icons";
import { Spin, Form, Input, Row, Col, Button, Drawer, Tabs, TabsProps, Select } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { useGetParcelExtraInfoByIdQuery, useUpdateParcelExtraInfoMutation } from "app/services";
import { useEffect, useState } from "react";
import { CSVLink } from 'react-csv'
import { CITIESFORSEARCH, LEADSOURCE, DESIREDPRODUCT, YESORNO, YESNOOTHER, SURROUNDINGNEIGHBORHOOD, TYPOGRAPHY, POWERLINES, RAILROADTRACKS, SURROUNDINGPRODUCT, INCORPORATED } from "utils";


export const ParcelQualificationSheet = ({ parcel_id, setIsModalVisible }: any) => {
  const [form] = Form.useForm();
  const [updateParcelExtraInfo] = useUpdateParcelExtraInfoMutation();
  const [parcelInfoDetails, setParcelInfoDetails] = useState<any>(null)
  const [showEditForm, setShowEditForm] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [csvData, setCsvData] = useState<any>([]);
  const {
    data: selectedParcel,
    isFetching: isFetchingParcel
  } = useGetParcelExtraInfoByIdQuery(parcel_id,
    {
      refetchOnReconnect: true,
      skip: !parcel_id
    },
  );
  /**
   * Handles the 'Cancel' action in the modal by hiding it.
   */
  const handleCancel = () => {
    if (showEditForm) {
      setShowEditForm(false)
    } else {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    setParcelInfoDetails(selectedParcel)
    if (selectedParcel) {
      const csvDataObj: any = {}
      Object.keys(selectedParcel).forEach((elem) => {
        csvDataObj[elem] = selectedParcel[elem]
      })
      setCsvData([csvDataObj])
    }
  }, [selectedParcel])

  useEffect(() => {
    form.setFieldsValue({
      ...selectedParcel
    });
    // eslint-disable-next-line
  }, [selectedParcel]);

  const onEditClick = async () => {
    if (showEditForm) {
      const updateData = {
        ...form.getFieldsValue(),
        parcelId: parcel_id
      }
      setIsLoading(true)
      await updateParcelExtraInfo(updateData).unwrap()
      setIsLoading(false)
      setShowEditForm(false)
    } else {
      setShowEditForm(true)
    }
  }


  const Tab = ({ property }: TabsProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
    </div>
  );

  return (
    <Drawer
      title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
        <span>Qualification Sheet - {parcel_id}</span>
        {!showEditForm ?
          <div>
            {csvData?.length ?
              <CSVLink filename={`QS-${parcel_id}`} title="Download" data={csvData}><DownloadOutlined /></CSVLink> : null
            }
            <Button style={{ marginLeft: 10 }} size="middle" onClick={onEditClick}>Edit</Button>
          </div>
          : null}
      </div>}
      placement="right"
      size="large"
      width={'80%'}
      onClose={handleCancel}
      destroyOnClose
      style={{ zIndex: 2000 }}
      open
    >
      {isFetchingParcel || isLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
        <Spin
          spinning={isFetchingParcel || isLoading}
          tip='Loading' size="large"
        ></Spin>
      </div> : !showEditForm ?
        <>
          <Tabs type='card'>
            <TabPane
              key="fromOwner"
              tab={
                <Tab
                  property="Information Obtained from Owner"
                />
              }
            >
              <Row>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Contact Owner: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.contact_owner}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Landowner first name: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.first_name}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Landowner last name: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.last_name}
                    </span>
                  </div>
                </Col>

                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Company name (if property owned in entity): </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.company_name}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Lead source: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.lead_source}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property address (or parcel number): </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_address}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property city: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_city}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property county: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_county}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Phone number: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.phone_number}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Email: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.email}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property listed? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_listed}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Other involved parties? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.other_involved_parties}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">If yes, who? And what is their contact info: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.involved_parties_contact}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property acreage: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_acreage}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Do you own water rights with the property? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.water_rights}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">If yes, how many acre feet of water? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.acre_feet_water}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Would you rather sell the land outright or partner? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.sell_or_partner}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">If you're selling, how much do you want for the property? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.selling_price}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Is there a product you would like to see on your land? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.preferred_product}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Is there any debt owed on the land? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.debt_on_land}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">If yes, how much? (estimated): </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.debt_estimate}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Are any neighbors interested in developing? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.neighbors_interested}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">If yes, which neighbors and do you have their contact info? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.neighbors_contact}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Due diligence already completed on land: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.due_diligence}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Do you own other land you would like to sell? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.other_land_to_sell}
                    </span>
                  </div>
                </Col>

              </Row>
            </TabPane>
            <TabPane
              key="fromOnline"
              tab={
                <Tab
                  property="Information Obtained Online"
                />
              }
            >
              <Row>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Google maps link: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.google_maps_link}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Parcel map link: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.parcel_map_link}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Zoning map link: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.zoning_map_link}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Zoning ordinance link: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.zoning_ordinance_link}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Utility map link(s): </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.utility_map_links}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Surrounding neighborhood: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.surrounding_neighborhood}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Topography (estimated): </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.topography_estimated}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Close to water? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.close_to_water}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Existing building or public use on property? </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.existing_building_or_public_use}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Power lines on property: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.power_lines_on_property}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Railroad tracks: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.railroad_tracks}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Boulders/Rock outcroppings: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.boulders_rock_outcroppings}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Basement next door: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.basement_next_door}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Temple within quarter mile: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.temple_within_quarter_mile}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Surrounding product: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.surrounding_product}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Property has access to road: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.property_access_to_road}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Parcel ID number: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.parcel_id_number}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Assigned zoning: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.assigned_zoning}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Allowed permitted/conditional uses within zone: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.allowed_permitted_uses_zone}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Allowed permitted/conditional uses in adjacent zones: </span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.allowed_permitted_uses_adjacent_zones}
                    </span>
                  </div>
                </Col>

              </Row>
            </TabPane>
            <TabPane
              key="fromCity"
              tab={
                <Tab
                  property="Information Obtained From City"
                />
              }
            >
              <Row>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Anything preventing project from moving forward</span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.preventing_project}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">City vision for property</span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.city_vision}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Previous proposed projects on property and their outcomes/reactions (if any)</span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.previous_projects}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Overlays or restrictions on property?</span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.project_restrictions}
                    </span>
                  </div>
                </Col>
                <Col span={12} style={{ marginBottom: 10 }}>
                  <div className="meta-detail">
                    <span className="meta-detail-name">Incorporated or unincorporated?</span>
                    <span className="meta-detail-value">
                      {parcelInfoDetails?.is_in_corporated}
                    </span>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </Tabs>


          {/* <Row style={{ marginTop: 25 }}>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Development status: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.development_status}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Electrical lines: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.electrical_lines}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Railroads: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.railroads}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Temple distance miles: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.temple_distance_miles}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Amount of builders matched: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.amount_builders_match}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Amount of developers matched: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.amount_developers_match}
                  </span>
                </div>

              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Sides touching developed: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.sides_touching_developed}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Basements next door: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.basements_next_door?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Bordering properties same owner: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.bordering_properties_same_owner?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Boulders overlap: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.boulders_overlap?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Close to water: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.close_to_water?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner is company: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.owner_is_company?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner out state: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.owner_out_state?.toString()}
                  </span>
                </div>
              </Col>
              <Col span={12} style={{ marginBottom: 10 }}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Slopes overlap: </span>
                  <span className="meta-detail-value">
                    {parcelInfoDetails?.slopes_overlap?.toString()}
                  </span>
                </div>
              </Col>

            </Row> */}
        </>
        : showEditForm ?
          <>
            <Tabs type='card'>
              <TabPane
                key="fromOwner"
                tab={
                  <Tab
                    property="Information Obtained from Owner"
                  />
                }
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size="middle" type="primary">Save</Button>
                </div>
                <Form
                  name="basic"
                  form={form}
                  className="form"
                  initialValues={{ remember: true }}
                  layout="vertical"
                >
                  <Form.Item label="Contact Owner" name="contact_owner">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Landowner Last Name" name="landowner_last_name">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Landowner First Name" name="landowner_first_name">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Company Name (if property owned in entity)" name="company_name">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Lead Source" name="lead_source" style={{ width: '50%' }}>
                    <Select
                      placeholder="Lead Source"
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {LEADSOURCE?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Property Address (or Parcel Number)" name="property_address">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Property City" name="property_city" style={{ width: '50%' }}>
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())}
                      options={CITIESFORSEARCH}
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    />
                    {/* <Select
                      placeholder="Property City"
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {CITIES?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select> */}
                  </Form.Item>
                  <Form.Item label="Property County" name="property_county">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Phone Number" name="phone_number">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Email" name="email">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Property Listed?" name="property_listed" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="If Yes, Who? And What is Their Contact Info" name="listed_contact_info">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Other Involved Parties?" name="other_involved_parties" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Property Acreage" name="property_acreage">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Do You Own Water Rights with the Property?" name="water_rights" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="If Yes, How Many Acre Feet of Water?" name="acre_feet_water">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Would You Rather Sell the Land Outright or Partner?" name="sell_or_partner" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="If You're Selling, How Much Do You Want for the Property?" name="property_price">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Is There a Product You Would Like to See on Your Land?" name="desired_product" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {DESIREDPRODUCT?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="If Yes, How Much? (Estimated)" name="product_estimate">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Is There Any Debt Owed on the Land?" name="land_debt" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Are Any Neighbors Interested in Developing?" name="neighbor_interest" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESNOOTHER?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="If Yes, Which Neighbors and Do You Have Their Contact Info?" name="neighbor_contact_info">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Due Diligence Already Completed on Land" name="due_diligence">
                    <Input style={{ width: '50%' }} size="large" />
                  </Form.Item>
                  <Form.Item label="Do You Own Other Land You Would Like to Sell?" name="other_land" style={{ width: '50%' }}>
                    <Select
                      dropdownStyle={{ zIndex: 9999, width: '50%' }}
                    >
                      {YESORNO?.map((elem: any) => (
                        <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="fromOnline"
                tab={
                  <Tab
                    property="Information Obtained Online"
                  />
                }
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size="middle" type="primary">Save</Button>
                </div>
                <Form.Item label="Google Maps Link" name="google_maps_link">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Parcel Map Link" name="parcel_map_link">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Zoning Map Link" name="zoning_map_link">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Zoning Ordinance Link" name="zoning_ordinance_link">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Utility Map Link(s)" name="utility_map_links">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>

                <Form.Item label="Surrounding Neighborhood" name="surrounding_neighborhood" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {SURROUNDINGNEIGHBORHOOD?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Topography (Estimated)" name="topography" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {TYPOGRAPHY?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Close to Water?" name="close_to_water" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESORNO?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Existing Building or Public Use on Property?" name="existing_building_public_use" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESORNO?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Power Lines on Property" name="power_lines_property" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {POWERLINES?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Railroad Tracks" name="railroad_tracks" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {RAILROADTRACKS?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Boulders/Rock Outcroppings" name="boulders_rock_outcroppings" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESORNO?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Basement Next Door" name="basement_next_door" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESORNO?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Temple Within Quarter Mile" name="temple_within_quarter_mile" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESORNO?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Surrounding Product" name="surrounding_product" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {SURROUNDINGPRODUCT?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Property Has Access to Road" name="property_access_road" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {YESNOOTHER?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Parcel ID Number" name="parcel_id_number">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Assigned Zoning" name="assigned_zoning">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Allowed Permitted/Conditional Uses Within Zone" name="allowed_uses_within_zone">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Allowed Permitted/Conditional Uses in Adjacent Zones" name="allowed_uses_adjacent_zones">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
              </TabPane>
              <TabPane
                key="fromCity"
                tab={
                  <Tab
                    property="Information Obtained From City"
                  />
                }
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size="middle" type="primary">Save</Button>
                </div>
                <Form.Item label="Anything preventing project from moving forward" name="preventing_project">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="City vision for property" name="city_vision">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Previous proposed projects on property and their outcomes/reactions (if any)" name="previous_projects">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>
                <Form.Item label="Overlays or restrictions on property?" name="project_restrictions">
                  <Input style={{ width: '50%' }} size="large" />
                </Form.Item>

                <Form.Item label="Incorporated or unincorporated?" name="is_in_corporated" style={{ width: '50%' }}>
                  <Select
                    dropdownStyle={{ zIndex: 9999, width: '50%' }}
                  >
                    {INCORPORATED?.map((elem: any) => (
                      <Select.Option key={elem} value={elem}>{elem}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

              </TabPane>
            </Tabs>
            {/* <Form
              name="basic"
              form={form}
              className="form"
              initialValues={{ remember: true }}
              layout="vertical"
            >
              <Form.Item label="Development status" name="development_status">
                <Input style={{width: '50%'}} size="large"/>
              </Form.Item>
              <Form.Item label="Electrical lines" name="electrical_lines">
                <Input style={{width: '50%'}} size="large"/>
              </Form.Item>
              <Form.Item label="Railroads" name="railroads">
                <Input style={{width: '50%'}} size="large"/>
              </Form.Item>
              <Form.Item label="Temple distance miles" name="temple_distance_miles">
                <Input style={{width: '50%'}} size="large"/>
              </Form.Item>
              <Form.Item label="Amount of buiders matched" name="amount_builders_match">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Amount of developers matched" name="amount_developers_match">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Sides touching developed" name="sides_touching_developed">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Basements next door" name="basements_next_door" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Bordering properties same owner" name="bordering_properties_same_owner" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Boulders overlap" name="boulders_overlap" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Close to water" name="close_to_water" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Owner is company" name="owner_is_company" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Owner out state" name="owner_out_state" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>
              <Form.Item label="Slopes overlap" name="slopes_overlap" valuePropName="checked">
                <Switch checkedChildren="True" unCheckedChildren="False" />
              </Form.Item>

            </Form> */}
          </> : null
      }
    </Drawer>
  )
}