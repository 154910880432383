import { Button, Form, Input, notification } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { McCleeryLogo } from 'components/Icons';
import { useLoginMutation } from 'app/services/auth.api';
import { Spin } from 'antd';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

// Icon for spin loading indicator
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// Login component definition
export const Login = () => {
  // State to manage loading state of login button
  const [loading, setLoading] = useState(false);

  // Login mutation hook
  const [login] = useLoginMutation();

  // History hook for navigation
  const { push } = useHistory();

  // Location hook to access previous location state
  const location: {
    state: { from: Location };
  } = useLocation();
  const previousLocation = location?.state?.from || {};

  // Function to handle form submission
  const onFinish = async (values: { email: string; password: string }) => {
    try {
      // Perform login mutation
      await login(values).unwrap();
      // Redirect to previous location after successful login
      push({ ...previousLocation });
    } catch (err: any) {
      // Display error notification if login fails
      notification.error({
        placement: 'topRight',
        message: 'Error while trying to log you in',
        description: err.data.message,
      });
    }
  };

  return (
    <div className='authentication-container'>
      <img src="/login-bg.jpeg" alt="Background" className="bg-img" />
      <div className="authentication-form">
        <div className="authentication-form-container">
          {/* McCleery logo */}
          <div className="authentication-form-img">
            <McCleeryLogo />
          </div>
          {/* Title */}
          <h2 className="authentication-form-title">Sign in to your account</h2>
          {/* Ant Design Form */}
          <Form
            name="authentication"
            className="form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            {/* Email input */}
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The entered E-mail is not valid E-mail!',
                },
              ]}
            >
              <Input
                placeholder="name@email.com"
                autoComplete="on"
                tabIndex={1}
              />
            </Form.Item>

            {/* Password input */}
            <Form.Item label="Password" className="password-item">
              {/* Forgot password link */}
              <Link className="forgot-password" to="/forgot-password">
                Forgot password
              </Link>
              {/* Password input field */}
              <Form.Item
                className='password-input'
                name="password"
                rules={[
                  { required: true, message: 'Please enter your Password!' },
                ]}
              >
                <Input.Password autoComplete="on" className='password-input' tabIndex={2} />
              </Form.Item>
            </Form.Item>

            {/* Submit button and create account link */}
            <Form.Item>
              <Button onClick={() => setLoading(true)} type="primary" htmlType="submit" className={loading ? 'loginBtnDisabled' : ''} tabIndex={3}>
                {/* Conditional rendering of button content based on loading state */}
                {
                  loading ?
                    (<Spin indicator={antIcon} />)
                    :
                    (
                      <p style={{ marginTop: '13px', position: 'fixed' }}>
                        Continue
                      </p>
                    )
                }
              </Button>
              {/* Link to sign up page */}
              <Link to="/signup" className="signup-btn">
                Create account
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      {/* Version display */}
      {window?.location?.host?.includes('getnaxxa') ?
        <div style={{ textAlign: 'right', marginRight: 10 }}>v79</div> : null}
    </div>
  );
};
