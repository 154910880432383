import { List, Popover, Spin, notification } from "antd";
import noImageAvailable from 'styles/images/noImageAvailable.png';
import {
  selectCurrentItem,
  useGetParcelInfoQuery,
  setItem,
  setSelectedProjectTypeValue,
  useGetAllParcelsQuery,
  selectShowingTotalValue,
  setShowProjectsTypeValue,
  setProjectIdValue,
  setShowParcelsTypeValue,
  setParcelIdValue,
  selectIsGridViewOpenValue,
  setCoordsPaginationValue,
  setSelectedItemDetailsOnMap,
  setParcelPageCoordsValue,
  setShowLoaderOnMap,
  setZoomValue,
  setMapCenterPointValue
} from "app/services";
import { Color, MatchedItemPopover } from "components";
import qs from 'query-string';
import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { Waypoint } from "react-waypoint";
import { ParcelInterface } from "types/parcels";
import { createMetaData } from "utils/metaDataHelpers";
import { ParcelCard } from "components/MetaData/ParcelCard";

export interface ParcelResultBoxProps {
  onOpenSelectedFilters: () => void;
  handleOnViewAllClick: (item: { id: string; type: string }) => void;
  closeInfoWindowOpen: () => void;
  isSelectedResultDrawerOpen?: boolean;
  paginatedQuerySearch: string;
  parcelsPagination: any;
  setParcelActivePage: (pageNumber: number) => void;
  setIsLoadingData: (selectedType: string) => void;
}

const ResultsList = ({
  dataSource,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  onWaypointEnter,
  hasMore,
  isSelectedResultDrawerOpen
}: {
  dataSource:
  | { type: string; item: ParcelInterface }[]
  | undefined;
  handleOnViewAllClick: (item: any) => void;
  closeInfoWindowOpen: () => void;
  onWaypointEnter?: () => void;
  hasMore?: boolean;
  isSelectedResultDrawerOpen?: boolean
}) => {
  const selectedItem = useSelector(selectCurrentItem);
  const dispatch = useDispatch();

  const skipSingleParcelQuery = useMemo(() => {
    return !(selectedItem && selectedItem.type === 'Parcel');
  }, [selectedItem]);

  const { search } = useLocation();


  const {
    data: selectedParcel,
    isFetching: isFetchingParcel,
    // isSuccess: isSuccessParcel,
  } = useGetParcelInfoQuery(
    {
      id: selectedItem?.id_serial || '',
      surveyTaker: localStorage.getItem('surveyEmail'),
      hypothetical:
        search && qs.parse(search)?.hypotheticals
          ? qs.parse(search)?.hypotheticals
          : '',
    },
    {
      refetchOnReconnect: true,
      skip: skipSingleParcelQuery,
    },
  );

  const data: any = useMemo(() => {
    if (selectedItem?.type === 'Parcel' && selectedParcel !== undefined)
      return selectedParcel
        ? {
          information: {
            title: selectedItem?.type || {},
            titleColor: Color.navy,
            image: noImageAvailable,
            alldata: selectedParcel?.allData || {},
            id_serial: selectedItem?.id_serial || '',
            existsInBookmarks: selectedParcel?.existsInBookmarks || '',
            data: createMetaData({
              item: {
                id: selectedItem?.id || '',
                ...selectedParcel,
              } as ParcelInterface,
              type: 'Parcel',
            }),
          },
          // matches: Object.entries(selectedParcel?.matches).map(
          //   ([property, { count }]) => ({
          //     property,
          //     total: selectedParcel?.matches[property],
          //   }),
          // ),
        }
        : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, selectedParcel]);

  const onListItemClick = (thisItem: any) => {
    if (document.getElementsByClassName('drawer-menu-1') && document.getElementsByClassName('drawer-menu-1').length) {
      let element: HTMLElement = document.getElementsByClassName('drawer-menu-1')[0] as HTMLElement;
      element.click();
    }
    if (thisItem?.item?.center?.coordinates?.length) {
      let lat = thisItem?.item?.center?.coordinates[1]
      let lng = thisItem?.item?.center?.coordinates[0]
      dispatch(setMapCenterPointValue({ lat, lng }))
      dispatch(setZoomValue(18))
    }
    dispatch(
      setItem({
        id: thisItem.item.id,
        type: thisItem.type,
        parcelId: thisItem.item.parcelId,
        formattedParcelId: thisItem.item.formattedParcelId,
        id_serial: thisItem.item.id_serial,
        parent_project_id: thisItem.item.parent_project_id,
        project_name_processed: thisItem.item.project_name_processed
      }),
    );
    dispatch(setSelectedProjectTypeValue(''))
    dispatch(setShowProjectsTypeValue(null))
    dispatch(setProjectIdValue(''))

    dispatch(setShowParcelsTypeValue(null))
    dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))
    dispatch(setParcelIdValue(''))
    dispatch(setSelectedItemDetailsOnMap(null))

    closeInfoWindowOpen();
  }

  const onOpenChange = (e: any) => {
    if (e === false && !isSelectedResultDrawerOpen) {
      dispatch(
        setItem(null)
      );
    }
  }

  useEffect(() => {
    if (dataSource?.length === 1 && search && qs.parse(search)?.parcelId) {
      onListItemClick(dataSource[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource])

  return (
    <List
      size="large"
      dataSource={dataSource}
      renderItem={(thisItem, i) => (
        <Popover
          key={thisItem.item.id}
          overlayClassName={isSelectedResultDrawerOpen ? "selected-item-popover zindex-background" : "selected-item-popover"}
          destroyTooltipOnHide
          onOpenChange={(e) => { onOpenChange(e); onListItemClick(thisItem) }}
          content={
            <Spin size='large'
              spinning={isFetchingParcel}
            // indicator={
            //   <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
            // }
            >
              <MatchedItemPopover
                onViewAllClick={() =>
                  handleOnViewAllClick({
                    id: thisItem.item.id,
                    type: thisItem.type,
                    parcelId: thisItem.item.parcelId,
                    formattedParcelId: thisItem.item.formattedParcelId,
                    parent_project_id: thisItem.item?.parent_project_id,
                    id_serial: thisItem.item.id_serial,
                    ownershipName: thisItem.item.ownershipName,
                    project_name_processed: thisItem.item.project_name_processed,
                  })
                }
                information={data && data.information}
                allData={data?.information?.alldata || {}}
              />
            </Spin>
          }
          trigger="click"
          placement="right"
        >
          <List.Item key={thisItem.item.id} className="font-small">
            <div
              onClick={() => {
                onListItemClick(thisItem)
              }}
              style={{ width: '100%' }}
            >
              <div style={{ display: 'flex' }}>
                <ParcelCard
                  title={thisItem?.type}
                  id_serial={thisItem?.item?.id_serial || ''}
                  item={thisItem}
                  titleColor={
                    thisItem?.type === 'Parcel' ? Color.navy : Color.green
                  }
                  data={createMetaData(thisItem)}
                  allData={data && data.information?.alldata}
                />
                {selectedItem && selectedItem?.id_serial === thisItem?.item?.id_serial ?
                  <img className="current-img" title='Selected' src='green-circle-icon.svg' alt='Selected' /> : null}
              </div>
              {/* {dataSource && hasMore && i === dataSource?.length - 1 && (
                <Waypoint
                  onEnter={(args) => {
                    onWaypointEnter();
                  }}
                />
              )} */}
            </div>
          </List.Item>
        </Popover>
      )}
    />
  );
};

export const ParcelResultBox = ({
  onOpenSelectedFilters,
  handleOnViewAllClick,
  closeInfoWindowOpen,
  isSelectedResultDrawerOpen,
  paginatedQuerySearch,
  parcelsPagination,
  setParcelActivePage,
  setIsLoadingData
}: ParcelResultBoxProps) => {
  const showingTotal: number | undefined = useSelector(selectShowingTotalValue);
  const { search } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [showingItems, setShowingItems] = useState<
    { type: string; item: ParcelInterface }[]
  >([]);
  const isGridViewOpen: any = useSelector(selectIsGridViewOpenValue)

  const {
    data: parcelsData,
    isFetching: isFetchingParcels,
    isSuccess: isSuccessParcels,
  } = useGetAllParcelsQuery(paginatedQuerySearch, {
    refetchOnReconnect: true,
    skip: isGridViewOpen
  });

  const dispatch = useDispatch()

  const listData = useMemo(() => {
    return parcelsData?.data?.map((item: any) => ({ item, type: 'Parcel' })) || [];
  }, [parcelsData]);

  useEffect(() => {
    if (parcelsData?.data) {
      dispatch(setParcelPageCoordsValue(parcelsData?.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelsData])


  useEffect(() => {
    !isFetchingParcels && isSuccessParcels &&
      setShowingItems((pv: any) =>
        currentPage === 1
          ? [...new Set([...[], ...(listData || [])])]
          : [...new Set([...pv, ...(listData || [])])],
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(setShowLoaderOnMap(isFetchingParcels))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingParcels,
    isSuccessParcels,
    listData,
    currentPage,
  ]);

  useEffect(() => {
    setParcelActivePage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    if (isGridViewOpen) return;
    // Set loading state based on fetching status
    setIsLoadingData(isFetchingParcels ? 'parcels' : '');

    // Increment parcel mount count
    const parcelMountCount = parseInt(localStorage.getItem("parcelMountCount") || '0') + 1;
    localStorage.setItem("parcelMountCount", parcelMountCount.toString());

    // Resolve memory leak issue using count
    if (parcelMountCount > 1 && !isFetchingParcels) {
      localStorage.removeItem("parcelMountCount");
      return;
    }

    // Display notifications based on parcel data and search status
    if (parcelMountCount === 1) {
      let notificationMessage = '';
      let notificationType = 'warn';

      if (!isFetchingParcels && !listData?.length) {
        notificationMessage = search ? 'No parcel matches available for the applied filters' : 'No parcels available';
      } else if (!isFetchingParcels && parcelsData) {
        notificationType = 'success';
        notificationMessage = 'Parcels retrieved successfully!';
      }

      if (notificationMessage) {
        const notificationFunc = notificationType === 'success' ? notification.success : notification.warning;

        notificationFunc({
          placement: 'topRight',
          message: notificationMessage,
        });

        if (search) {
          onOpenSelectedFilters();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingParcels])

  return (
    <div>
      <ResultsList
        isSelectedResultDrawerOpen={isSelectedResultDrawerOpen}
        dataSource={showingItems}
        handleOnViewAllClick={handleOnViewAllClick}
        closeInfoWindowOpen={closeInfoWindowOpen}
        onWaypointEnter={() =>
          (!isFetchingParcels &&
            showingItems?.length <= 990) || showingTotal ?
            setCurrentPage((page) => page + 1) : null

        }
        hasMore={!parcelsPagination || currentPage < parcelsPagination?.totalPages}
      // hasMore={true}
      />
      {isFetchingParcels &&
        currentPage !== 1 && (
          <Spin
            spinning={true}
            // indicator={
            //   <Loading3QuartersOutlined style={{ fontSize: 20 }} spin />
            // }
            tip="Loading..."
            className="cust-spin"
          />
        )}
    </div>
  )
}