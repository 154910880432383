import { Row, Col, Tabs, TabsProps, Pagination, Button } from "antd";
import Skeleton from 'react-loading-skeleton';
import { selectCoordsPaginationValue, selectDrawerWidthValue, selectIsCountLoadingValue, selectProjectsInnerActiveTabValue, setCoordsPaginationValue, setDrawerWidthValue, setParentProjectListDataValue, setProjectsInnerActiveTabValue, setSelectedItemDetailsOnMap, setShowParcelsTypeValue, setShowProjectsTypeValue, setZoomValue, useGetAllParentProjectsQuery, useGetAllProjectsQuery, useGetParentProjectsCountQuery, useGetProjectsCountQuery } from "app/services";
import { ResultCard, Color } from "components";
import { ProjectInterface } from 'types/projects';
import { useState, useMemo, useEffect } from "react";
import { createMetaData } from "utils/metaDataHelpers";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export interface ProjectGridViewProps {
  setProjectsTotal: (total: number) => void;
  closeViewAll: () => void;
}

const ResultsGrid = ({
  dataSource,
  closeViewAll,
}: {
  dataSource:
  | { type: string; item: ProjectInterface }[]
  | undefined;
  closeViewAll: () => void;
}) => {

  return (
    <>
      <Row
        style={{ marginBottom: 15 }}
        className="result-content"
        gutter={[
          { xs: 6, sm: 12, md: 16, lg: 24 },
          { xs: 6, sm: 12, md: 16, lg: 24 },
        ]}
      >
        {dataSource &&
          dataSource.map((result, i) => (
            <Col key={i}>
              {/* <Skeleton className="grid-skeleton" height={160} count={1} /> */}
              <ResultCard
                title={result?.type}
                allData={result}
                from='viewAll'
                titleColor={Color.green}
                data={createMetaData(result)}
                closeViewAll={closeViewAll}
                id={result.item?.id}
                parcelId={result.item?.parcelId}
                id_serial={result.item?.id_serial}
                formattedParcelId={result.item?.formattedParcelId}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

export const ProjectGridView = ({ setProjectsTotal, closeViewAll }: ProjectGridViewProps) => {
  const { search } = useLocation();
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(20)
  const [currentPage, setCurrentPage] = useState(1);
  const { TabPane } = Tabs;
  const [parentProjectsCount, setParentProjectsCount] = useState('')
  const [childProjectsCount, setChildProjectsCount] = useState('')

  const innerActiveTab: any = useSelector(selectProjectsInnerActiveTabValue);
  const isCountLoading: any = useSelector(selectIsCountLoadingValue);

  const drawerWidthValue: any = useSelector(selectDrawerWidthValue)

  const projectPaginatedQuerySearch = useMemo(
    () => `?page=${currentPage}&take=${limit}&${search.substring(1)}&surveyTaker=${localStorage.getItem('surveyEmail')}`,
    [limit, currentPage, search],
  );

  const [showingItems, setShowingItems] = useState<{ type: string; item: ProjectInterface }[]>([]);

  const {
    data: projectsData,
    isFetching: isFetchingProjects,
    isSuccess: isSuccessProjects,
  } = useGetAllParentProjectsQuery(projectPaginatedQuerySearch, {
    refetchOnReconnect: true,
    skip: innerActiveTab === 'childProject'
  });

  const {
    data: childProjectsData,
    isFetching: isFetchingChildProjects,
    isSuccess: isSuccessChildProjects,
  } = useGetAllProjectsQuery(projectPaginatedQuerySearch, {
    refetchOnReconnect: true,
    skip: innerActiveTab === 'parentProject'
  });

  const listData = useMemo(() => {
    return projectsData?.map((item: any) => ({ item, type: 'Project' }));
  }, [projectsData]);

  const childListData = useMemo(() => {
    return childProjectsData?.data?.map((item: any) => ({ item, type: 'Project' }));
  }, [childProjectsData]);


  const { data: parentProjectsPagination } = useGetParentProjectsCountQuery<any>(
    projectPaginatedQuerySearch,
    {
      refetchOnReconnect: true,
    },
  );

  const { data: childProjectsPagination } = useGetProjectsCountQuery<any>(
    projectPaginatedQuerySearch,
    {
      refetchOnReconnect: true,
    },
  );

  useEffect(() => {
    if (
      typeof parentProjectsPagination?.totalItems !== 'undefined' &&
      typeof childProjectsPagination?.totalItems !== 'undefined'
    ) {
      setProjectsTotal(
        parentProjectsPagination?.totalItems +
        childProjectsPagination?.totalItems,
      );
    }
    setParentProjectsCount(parentProjectsPagination?.totalItems || '');
    setChildProjectsCount(childProjectsPagination?.totalItems || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parentProjectsPagination,
    childProjectsPagination
  ]);

  const totalPaginationItems = useMemo(() => {
    setCurrentPage(1);
    if (innerActiveTab === 'parentProject') {
      return parseInt(parentProjectsCount || '0');
    } else {
      return parseInt(childProjectsCount || '0');
    }
  }, [innerActiveTab, parentProjectsCount, childProjectsCount]);
  const coordsPagination:any = useSelector(selectCoordsPaginationValue)
  useEffect(() => {
    setCurrentPage(coordsPagination?.page)
  }, [coordsPagination])

  useEffect(() => {
    setCurrentPage(1)
  }, [limit])

  useEffect(() => {
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    if (!isFetchingProjects && isSuccessProjects && innerActiveTab === 'parentProject') {
      // setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      const allShowingItems = [...(listData || [])];
      setShowingItems(allShowingItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingProjects,
    isSuccessProjects,
    listData,
    innerActiveTab
  ]);

  useEffect(() => {
    if (!isFetchingChildProjects && isSuccessChildProjects && innerActiveTab === 'childProject') {
      // setShowingItems((pv) => [...new Set([...pv, ...(listData || [])])]);
      const allShowingItems = [...(childListData || [])];
      setShowingItems(allShowingItems);
    }
    dispatch(setZoomValue(6))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingChildProjects,
    isSuccessChildProjects,
    childListData,
    innerActiveTab,
    setZoomValue
  ]);

  const Tab = ({ property }: TabsProps) => (
    <div className="tab-title">
      <div className="name">
        <span>{property}</span>
      </div>
    </div>
  );

  const viewAllOnMapHandler = () => {
    dispatch(setDrawerWidthValue(680))
    dispatch(setShowParcelsTypeValue(null))
    dispatch(setShowProjectsTypeValue(null))
    dispatch(setCoordsPaginationValue({ page: currentPage, limit }))
    if (innerActiveTab === 'parentProject') {
      dispatch(setParentProjectListDataValue(projectsData))
      dispatch(setSelectedItemDetailsOnMap({ viewType: 'Grid view', selectedType: 'parent projects', totalCount: parentProjectsCount }))
    } else {
      dispatch(setParentProjectListDataValue(childProjectsData?.data))
      dispatch(setSelectedItemDetailsOnMap({ viewType: 'Grid view', selectedType: 'child projects', totalCount: childProjectsCount }))
    }
    // dispatch(setZoomValue(4))
    // closeViewAll()
  }


  return (
    <div>
      <Tabs type='card' activeKey={innerActiveTab} onChange={(e) => {
        dispatch(setCoordsPaginationValue({ page: 1, limit: 400 }))
        dispatch(setProjectsInnerActiveTabValue(e))
        dispatch(setSelectedItemDetailsOnMap(null))
      }}>
        <TabPane
          key="parentProject"
          tab={
            <Tab
              property={isFetchingProjects && innerActiveTab === 'parentProject' ? `Parent Projects (Loading...)` : parentProjectsCount ? `Parent Projects (${parentProjectsCount})` : `Parent Projects`}
            />
          }
        >
        </TabPane>
        <TabPane
          key="childProject"
          tab={
            <Tab
              property={isFetchingChildProjects && innerActiveTab === 'childProject' ? `Child Projects (Loading...)` : childProjectsCount ? `Child Projects (${childProjectsCount})` : `Child Projects`}
            />
          }
        >
        </TabPane>
      </Tabs>
      {isFetchingProjects || isFetchingChildProjects ? (
        <>
          <Row
            style={{ marginBottom: 30 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
          <Row
            style={{ marginBottom: 30 }}
            className="result-content"
            gutter={[
              { xs: 6, sm: 12, md: 16, lg: 24 },
              { xs: 6, sm: 12, md: 16, lg: 24 },
            ]}
          >
            {Array(7)
              .fill('')
              .map(() => {
                return (
                  <Col key={1} style={{ flex: 1 }}>
                    <Skeleton
                      className="grid-skeleton"
                      height={160}
                      count={1}
                    />
                  </Col>
                );
              })}
          </Row>
        </>
      ) : (
        <div>
          <div className="grid-view-on-map-div">
            <Button className='title-btn' type="primary" onClick={viewAllOnMapHandler}>
              View all on map
            </Button>
          </div>
          <ResultsGrid
            dataSource={showingItems}
            closeViewAll={closeViewAll}
          />
        </div>
      )}
      <div className="pagination-container" style={{ width: drawerWidthValue }}>
        <Pagination
          current={currentPage}
          total={totalPaginationItems}
          pageSize={limit}
          showTotal={(total, range) => isCountLoading ? 'Loading...' : `${range[0]}-${range[1]} of ${total} items`}
          onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
          onChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  )
}