import { ActiveTabType } from 'types/common';

export const CITIES = [
  'Adamsville',
  'Beaver',
  'Greenville',
  'Manderfield',
  'Milford',
  'Minersville',
  'Sulphurdale',
  'Bear River City',
  'Beaverdam',
  'Bothwell',
  'Brigham City',
  'Collinston',
  'Corinne',
  'Deweyville',
  'Elwood',
  'Fielding',
  'Garland',
  'Honeyville',
  'Mantua',
  'Penrose',
  'Perry',
  'Plymouth',
  'Portage',
  'Riverside',
  'South Willard',
  'Thatcher',
  'Tremonton',
  'Wheelon',
  'Willard',
  'Blue Creek',
  'Cedar Creek',
  'Etna',
  'Grouse Creek',
  'Howell',
  'Lucin',
  'Lynn',
  'Park Valley',
  'Rosette',
  'Snowville',
  'Yost',
  'Amalga',
  'Avon',
  'Benson',
  'Cache',
  'Clarkston',
  'College Ward',
  'Cornish',
  'Cove',
  'Hyde Park',
  'Hyrum',
  'Lewiston',
  'Logan',
  'Mendon',
  'Millville',
  'Newton',
  'Nibley',
  'North Logan',
  'Paradise',
  'Peter',
  'Providence',
  'Richmond',
  'River Heights',
  'Smithfield',
  'Trenton',
  'Wellsville',
  'Carbonville',
  'Castle Gate',
  'Clear Creek',
  'East Carbon',
  'Helper',
  'Hiawatha',
  'Kenilworth',
  'Price',
  'Scofield',
  'Spring Glen',
  'Sunnyside',
  'Wellington',
  'West Wood',
  'Dutch John',
  'Flaming Gorge',
  'Manila',
  'Red Canyon',
  'Clearfield',
  'Clinton',
  'Fruit Heights',
  'Kaysville',
  'Layton',
  'South Weber',
  'Sunset',
  'Syracuse',
  'West Point',
  'Bountiful',
  'Centerville',
  'Farmington',
  'North Salt Lake',
  'West Bountiful',
  'Woods Cross',
  'Ioka',
  'Monarch',
  'Myton',
  'Neola',
  'Roosevelt',
  'Altamont',
  'Altonah',
  'Bluebell',
  'Boneta',
  'Bridgeland',
  'Duchesne',
  'Fruitland',
  'Hanna',
  'Mountain Home',
  'Strawberry',
  'Tabiona',
  'Talmage',
  'Castle Dale',
  'Clawson',
  'Cleveland',
  'Elmo',
  'Emery',
  'Ferron',
  'Huntington',
  'Mohrland',
  'Moore',
  'Orangeville',
  'Victor',
  'Antimony',
  'Bryce Canyon City',
  'Cannonville',
  'Escalante',
  'Hatch',
  'Henrieville',
  'Osiris',
  'Panguitch',
  'Spry',
  'Tropic',
  'Widtsoe',
  'Ticaboo',
  'Boulder',
  'Castle Valley',
  'Cisco',
  'Green River',
  'Moab',
  'Thompson Springs',
  'Brian Head',
  'North View',
  'Cedar City',
  'Cedar Highlands',
  'Enoch',
  'Hamiltons Fort',
  'Kanarraville',
  'Paragonah',
  'Parowan',
  'Summit',
  'Beryl',
  'Beryl Junction',
  'Lund',
  'Modena',
  'Newcastle',
  'Old Irontown',
  'Partoun',
  'Trout Creek',
  'Levan',
  'Mona',
  'Nephi',
  'Rocky Ridge',
  'Eureka',
  'Mammoth',
  'Bullfrog',
  'Alton',
  'Big Water',
  'Duck Creek Village',
  'Glendale',
  'Kanab',
  'Mt Caramel Junction',
  'Mt Carmel',
  'Orderville',
  'Cove Fort',
  'Eskdale',
  'Gandy',
  'Garrison',
  'Abraham',
  'Delta',
  'Deseret',
  'Fillmore',
  'Flowell',
  'Hinckley',
  'Holden',
  'Kanosh',
  'Leamington',
  'Lynndyl',
  'Meadow',
  'Oak City',
  'Oasis',
  'Scipio',
  'Sutherland',
  'Croydon',
  'Morgan',
  'Mountain Green',
  'Peterson',
  'Angle',
  'Circleville',
  'Greenwich',
  'Junction',
  'Kingston',
  'Marysvale',
  'Garden',
  'Garden City',
  'Laketown',
  'Randolph',
  'Woodruff',
  'Magna',
  'Cottonwood Heights',
  'Emigration County',
  'Holladay',
  'Kearns',
  'Midvale',
  'Millcreek',
  'Murray',
  'Salt Lake City',
  'South Salt Lake',
  'Taylorsville',
  'West Jordan',
  'West Valley',
  'Brighton',
  'Bingham Canyon',
  'Bluffdale',
  'Copperton',
  'Draper',
  'Granite',
  'Herriman',
  'Riverton',
  'Sandy',
  'South Jordan',
  'White City',
  'Fry Canyon',
  'Halls Crossing',
  'Hite',
  'Spanish Valley',
  'Aneth',
  'Blanding',
  'Bluff',
  'Halchita',
  'La Sal',
  'Mexican Hat',
  'Montezuma Creek',
  'Monticello',
  'Navajo Mountain',
  'Oljato-Monument Valley',
  'Tselekai Dezza',
  'White Mesa',
  'Axtell',
  'Centerfield',
  'Chester',
  'Ephraim',
  'Fairview',
  'Fayette',
  'Fountain Green',
  'Freedom',
  'Gunnison',
  'Indianola',
  'Manti',
  'Mayfield',
  'Milburn',
  'Moroni',
  'Mt Pleasant',
  'Spring City',
  'Sterling',
  'Wales',
  'Annabella',
  'Aurora',
  'Austin',
  'Burrville',
  'Central Valley',
  'Elsinore',
  'Glenwood',
  'Joseph',
  'Koosharem',
  'Monroe',
  'Redmond',
  'Richfield',
  'Salina',
  'Sevier',
  'Sigurd',
  'Venice',
  'Whipup',
  'Coalville',
  'Echo',
  'Francis',
  'Hoytsville',
  'Kamas',
  'Marion',
  'Oakley',
  'Peoa',
  'Samak',
  'Wanship',
  'Woodland',
  'Henefer',
  'Alta',
  'Deer Mountain',
  'Hideout',
  'Keetley',
  'Kimball Junction',
  'Park City',
  'Silver Summit',
  'Snyderville',
  'Summit Park',
  'Faust',
  'Rush Valley',
  'Vernon',
  'Mills Junction',
  'Stansbury Park',
  'Burmester',
  'Erda',
  'Grantsville',
  'Lincoln',
  'Stockton',
  'Tooele',
  'Aragonite',
  'Dugway',
  'Gold Hill',
  'Ibapah',
  'Skull Valley',
  'Terra',
  'Wendover',
  'Ballard',
  'Fort Duchesne',
  'Gusher',
  'Leeton',
  'Randlett',
  'Tridell',
  'Whiterocks',
  'Lapoint',
  'Ouray',
  'Bonanza',
  'Jensen',
  'Maeser',
  'Naples City',
  'Vernal',
  'Cedar Fort',
  'Cedar Valley',
  'Fairfield',
  'Eagle Mountain',
  'Alpine',
  'American Fork',
  'Cedar Hills',
  'Highland',
  'Lehi',
  'Lindon',
  'Orem',
  'Pleasant Grove',
  'Provo',
  'Vineyard',
  'Benjamin',
  'Elk Ridge',
  'Lake Shore',
  'Mapleton',
  'Palmyra',
  'Payson',
  'Salem',
  'Santaquin',
  'Spanish Fork',
  'Spring Lake',
  'Springville',
  'West Mountain',
  'Woodland Hills',
  'Dividend',
  'Elberta',
  'Genola',
  'Goshen',
  'Mosida',
  'Birdseye',
  'Soldier Summit',
  'Thistle',
  'Tucker',
  'Saratoga Springs',
  'Charleston',
  'Daniel',
  'Heber',
  'Independence',
  'Interlaken',
  'Midway',
  'Timber Lakes',
  'Wallsburg',
  'New Harmony',
  'Apple Valley',
  'Grafton',
  'Hildale',
  'Rockville',
  'Springdale',
  'Virgin',
  'Hurricane',
  'Ivins',
  'La Verkin',
  'Leeds',
  'Pintura',
  'Santa Clara',
  'St George',
  'Toquerville',
  'Washington',
  'Central',
  'Dammeron Valley',
  'Gunlock',
  'Montoqua',
  'Pine Valley',
  'Shem',
  'Shivwits',
  'Veyo',
  'Enterprise',
  'Bicknell',
  'Caineville',
  'Fremont',
  'Fruita',
  'Grover',
  'Hanksville',
  'Loa',
  'Lyman',
  'Teasdale',
  'Torrey',
  'Eden',
  'Huntsville',
  'Liberty',
  'Powder Mountain',
  'Wolf Creek',
  'Farr West',
  'Harrisville',
  'Hill Air Force Base',
  'Hooper',
  'Marriot-Slaterville',
  'North Ogden',
  'Ogden',
  'Plain City',
  'Pleasant View',
  'Riverdale',
  'Roy',
  'South Ogden',
  'Taylor',
  'Uintah',
  'Warren',
  'Washington Terrace',
  'West Haven',
  'West Weber',
].sort();

export const STYLEOFLOTS = [
  {
    headerTitle: 'All Apartments/Condos',
    options: [
      'Apartments/Condos 2-30',
      'Apartments/Condos 31-60',
      'Apartments/Condos 61-90',
      'Apartments/Condos 91-120',
      'Apartments/Condos 121-200',
      'Apartments/Condos 201-500',
      'Apartments/Condos 500+',
    ],
    keyValue: 'condos',
  },
  {
    headerTitle: 'All Townhouse',
    options: [
      'Townhouse Pads 2-30',
      'Townhouse Pads 16-60',
      'Townhouse Pads 50-90',
      'Townhouse Pads 81+',
    ],
    keyValue: 'townhouse',
  },
  {
    headerTitle: 'All Single Family',
    options: [
      'Single Family 1-3',
      'Single Family 4-15',
      'Single Family 10-35',
      'Single Family 30-55',
      'Single Family 45-75',
      'Single Family 65+',
    ],
    keyValue: 'singleFamily',
  },
  {
    headerTitle: 'All Commercial',
    options: [
      'Commercial Office',
      'Commercial Retail',
      'Assisted Living',
      'Industrial & Manufacturing',
      'Storage Units',
      'Mobile Parks',
      // 'Mobile Home',
      'Hospitality',
      'Hospitals',
    ],
    keyValue: 'commercial',
  },
  {
    headerTitle: 'All Land',
    options: ['Land'],
    keyValue: 'land',
  },
];

export const UTAHAREATAGS = [
  'North Salt Lake County',
  'South Salt Lake County',
  'Magna/Stansbury Park',
  'North Utah County',
  'South Utah County',
  'West Utah County',
  'South West Utah County',
  'Spanish Fork Canyon Area',
  'Cedar Fort Area',
  'Tooele County',
  'West Tooele County',
  'Central Nevada Border Area',
  'St George Area',
  'East Washington County',
  'West Washington County',
  'South Davis County',
  'North Davis County',
  'Weber County Ogden',
  'Weber County Eden',
  'Heber Area',
  'Park City Area',
  'East Summit County',
  'Nephi Area',
  'Cedar City Area',
  'West Iron County',
  'Brian Head Area',
  'Cache County',
  'Tremonton/Brigham City Area',
  'West Box Elder County',
  'Bear Lake Area',
  'East Rich County',
  'Emery County',
  'Sevier County',
  'Morgan County',
  'Vernal Area',
  'East Duchesne County',
  'West Duchesne County',
  'Carbon County',
  'Sanpete County',
  'Grand County',
  'San Juan County',
  'Beaver County',
  'Millard County',
  'Garfield County',
  'Kanab Area',
  'Wayne County',
  'Piute County',
  'Daggett County',
  'Glen Canyon Area',
].sort();

export const ZIPS = [
  '84095',
  '84118',
  '84015',
  '84043',
  '84404',
  '84119',
  '84120',
  '84041',
  '84074',
  '84003',
  '84020',
  '84062',
  '84321',
  '84010',
  '84790',
  '84604',
  '84065',
  '84770',
  '84660',
  '84121',
  '84058',
  '84057',
  '84401',
  '84067',
  '84123',
  '84037',
  '84084',
  '84403',
  '84606',
  '84088',
  '84106',
  '84116',
  '84601',
  '84663',
  '84107',
  '84047',
  '84405',
  '84414',
  '84094',
  '84078',
  '84075',
  '84092',
  '84070',
  '84115',
  '84104',
  '84044',
  '84651',
  '84302',
  '84780',
  '84128',
  '84117',
  '84109',
  '84341',
  '84093',
  '84025',
  '84040',
  '84721',
  '84032',
  '84124',
  '84097',
  '84105',
  '84108',
  '84103',
  '84720',
  '84054',
  '84098',
  '84102',
  '84737',
  '84014',
  '84087',
  '84335',
  '84655',
  '84501',
  '84337',
  '84111',
  '84066',
  '84050',
  '84029',
  '84042',
  '84004',
  '84532',
  '84664',
  '84653',
  '84701',
  '84315',
  '84738',
  '84060',
  '84319',
  '84332',
  '84036',
  '84627',
  '84765',
  '84648',
  '84741',
  '84624',
  '84049',
  '84339',
  '84312',
  '84318',
  '84101',
  '84511',
  '84745',
  '84017',
  '84310',
  '84647',
  '84754',
  '84526',
  '84021',
  '84642',
  '84713',
  '84340',
  '84056',
  '84634',
  '84535',
  '84761',
  '84631',
  '84645',
  '84629',
  '84784',
  '84333',
  '84534',
  '84654',
  '84528',
  '84320',
  '84325',
  '84317',
  '84112',
  '84759',
  '84751',
  '84622',
  '84326',
  '84774',
  '84523',
  '84536',
  '84646',
  '84542',
  '84328',
  '84052',
  '84725',
  '84071',
  '84055',
  '84662',
  '84513',
  '84026',
  '84537',
  '84757',
  '84033',
  '84314',
  '84083',
  '84520',
  '84053',
  '84724',
  '84013',
  '84782',
  '84746',
  '84747',
  '84082',
  '84307',
  '84633',
  '84064',
  '84632',
  '84525',
  '84531',
  '84752',
  '84518',
  '84639',
  '84006',
  '84722',
  '84327',
  '84637',
  '84039',
  '84711',
  '84620',
  '84649',
  '84652',
  '84783',
  '84726',
  '84723',
  '84301',
  '84521',
  '84750',
  '84714',
  '84022',
  '84324',
  '84635',
  '84512',
  '84028',
  '84758',
  '84305',
  '84779',
  '84630',
  '84628',
  '84007',
  '84001',
  '84665',
  '84636',
  '84069',
  '84311',
  '84760',
  '84657',
  '84027',
  '84051',
  '84776',
  '84643',
  '84767',
  '84338',
  '84623',
  '84739',
  '84085',
  '84626',
  '84144',
  '84076',
  '84667',
  '84522',
  '84046',
  '84309',
  '84072',
  '84775',
  '84638',
  '84330',
  '84086',
  '84773',
  '84716',
  '84002',
  '84038',
  '84080',
  '84715',
  '84766',
  '84744',
  '84718',
  '84730',
  '84734',
  '84656',
  '84764',
  '84073',
  '84539',
  '84756',
  '84530',
  '84308',
  '84061',
  '84035',
  '84306',
  '84729',
  '84331',
  '84736',
  '84740',
  '84749',
  '84742',
  '84018',
  '84735',
  '84772',
  '84763',
  '84031',
  '84710',
  '84316',
  '84023',
  '84529',
  '84731',
  '84113',
  '84063',
  '84034',
  '84334',
  '84781',
  '84533',
  '84336',
  '84640',
  '84329',
  '84516',
  '84712',
  '84304',
  '84733',
  '84540',
  '84313',
  '84621',
  '84762',
  '84755',
  '84719',
  '84732',
  '84024',
  '84728',
  '84743',
  '84753',
  '84515',
  '84180',
].sort();

export const REALESTATEPRODUCTS = [
  '55+ Communities',
  'Apartments/Condos',
  'Assisted Living',
  'Building Flipping',
  'Commercial Office',
  'Commercial Retail',
  'Distressed Properties',
  "Don't Invest In Real Estate",
  'Hospitality',
  'Hospitals',
  'House Flipping',
  'Industrial & Manufacturing',
  'Land',
  'Mobile Parks',
  'Mortgages',
  'Multi Unit (Duplex, Triplex, Fourplex)',
  'Notes',
  'Ranches',
  'Resort/Airbnb',
  'Single Family',
  'Storage Units',
  'Student Housing',
  'Townhomes',
];

export const INVESTMENTAMOUNT = [
  'No Minimum',
  '$100,000 or less',
  '$200,000',
  '$300,000',
  '$400,000',
  '$500,000',
  '$750,000',
  '$1,000,000',
  '$1,500,000',
  '$2,000,000',
  '$2,500,000',
  '$3,000,000',
  '$3,500,000',
  '$4,000,000',
  '$5,000,000',
  '$6,000,000',
  '$7,000,000',
  '$8,000,000',
  '$9,000,000',
  '$10,000,000',
  '$11,000,000',
  '$12,000,000',
  '$13,000,000',
  '$14,000,000',
  '$15-20 Mil',
  '$20-25 Mil',
  '$25-30 Mil',
  '$30-50 Mil',
  '$50-100 Mil',
  '$100-150 Mil',
  '$150-250 Mil',
  '$250-500 Mil',
  '$500 Mil+',
];

export const INVESTORORLENDER = ['Investor', 'Lender', 'Both'];

export const REALESTATEPRODUCTSDEVELOP = [
  'Apartments/Condos',
  'Townhomes',
  'Single family',
  'Commercial (Office)',
  'Commercial (Retail)',
  'Storage Units',
  'Industrial & Manufacturing',
  'Hospitals',
  'Mobile Parks',
  'Ranches',
  'Resort',
  '55+ Communities',
  'Assisted Living',
  'Hospitality',
];

export const PAPERRESIDENTIAL = [
  'Both',
  'Paper Subdivision',
  'Paper Commercial',
  'Neither',
];

export const CurrentZoningCounties = [
  {
    headerTitle: 'Utah',
    options: [
      'A-40',
      'APO',
      'CE-1',
      'CE-2',
      'G-1',
      'HS-1',
      'I-1',
      'M&G-1',
      'NC-1',
      'NHO',
      'PC',
      'PF',
      'RR-5',
      'TDR-R',
      'TDR-S',
      'TR-5',
    ],
    keyValue: 'currentZoningCountyUtah',
  },
  {
    headerTitle: 'Salt Lake',
    options: [
      'A-1',
      'A-10',
      'A-2',
      'A-20',
      'A-5',
      'AOZ',
      'C-1',
      'C-2',
      'C-3',
      'C-V',
      'F-1',
      'FA-10',
      'FA-2.5',
      'FA-20',
      'FA-5',
      'FM-10',
      'FM-20',
      'FR-0.5',
      'FR-1',
      'FR-10',
      'FR-100',
      'FR-2.5',
      'FR-20',
      'FR-5',
      'FR-50',
      'M-1',
      'M-2',
      'MD-1',
      'MD-3',
      'O-R-D',
      'P-C',
      'R-1-10',
      'R-1-15',
      'R-1-21',
      'R-1-3',
      'R-1-4',
      'R-1-43',
      'R-1-5',
      'R-1-6',
      'R-1-7',
      'R-1-8',
      'R-2-10',
      'R-2-10C',
      'R-2-6.5',
      'R-2-8',
      'R-4-8.5',
      'R-M',
      'RCOZ',
      'RMH',
      'S-1-G',
      'FCOZ',
      'PF',
    ],
    keyValue: 'currentZoningCountySaltLake',
  },
  {
    headerTitle: 'Davis',
    options: [
      'A-1',
      'A-10',
      'A-5',
      'AE',
      'C-1',
      'C-2',
      'F-1',
      'M-1',
      'NR',
      'PUD',
      'R-1',
      'R-2',
      'R-3',
    ],
    keyValue: 'currentZoningCountyDavis',
  },
];

export const CurrentZoningCities = [
  {
    headerTitle: 'Alpine',
    options: [
      'H-1',
      'PD-1',
      'PF',
      'PR 2.2',
      'PR 3.4',
      'R-1-11000',
      'R-1-15000',
      'RR-1-20000',
      'SC-1',
      'TR-1',
    ],
    keyValue: 'currentZoningCityAlpine',
  },
  {
    headerTitle: 'American Fork',
    options: [
      'CC-1',
      'CC-2',
      'GC-1',
      'GC-2',
      'HFO-1',
      'I-1',
      'M-1',
      'PC',
      'PF',
      'PI-1',
      'PI-1(O)',
      'PO-1',
      'PR-2',
      'PR-2.3',
      'PR-3',
      'R-1-12000',
      'R-1-15000',
      'R-1-20000',
      'R-1-7500',
      'R-1-9000',
      'R-2-7500',
      'R-3-7500',
      'R-4-7500',
      'RA-1',
      'RA-5',
      'S-1',
      'SC-1',
      'SP',
      'TDR',
      'TOD',
    ],
    keyValue: 'currentZoningCityAmericanFork',
  },
  {
    headerTitle: 'Cedar Fort',
    options: ['C', 'CR', 'LI', 'MG-40', 'RA', 'RA-1', 'RA-10', 'RA-4', 'RA-5'],
    keyValue: 'currentZoningCityCedarFort',
  },
  {
    headerTitle: 'Cedar Hills',
    options: [
      'RR-1-20000',
      'PD-1',
      'PR',
      'R-1-11000',
      'SC-1',
      'PF',
      'H-1',
      'TR-1',
      'R-1-15000',
      '2.2',
      '3.4',
    ],
    keyValue: 'currentZoningCityCedarHills',
  },
  {
    headerTitle: 'Eagle Mountain',
    options: [
      'RD2',
      'M&I',
      'CN',
      'OS-I',
      'RC',
      'RA1',
      'R3',
      'MF2',
      'OP',
      'RA2',
      'CR',
      'CC',
      'RD1',
      'LMD',
      'MF1',
      'MEC',
      'R2',
      'R1',
      'FR',
      'APA',
      'OS-N',
    ],
    keyValue: 'currentZoningCityEagleMountain',
  },
  {
    headerTitle: 'Elk Ridge',
    options: [
      'R&L-1-20000',
      'C-1',
      'R-1-20000',
      'RR-1',
      'R-1-15000',
      'HR-1',
      'CE-2',
      'R-1-12000',
      'MHD',
      'PF',
      'TDR',
    ],
    keyValue: 'currentZoningCityElkRidge',
  },
  {
    headerTitle: 'Fairfield',
    options: ['AR-10', 'AR-40', 'APO'],
    keyValue: 'currentZoningCityFairfield',
  },
];

export const TABS: ActiveTabType[] = ['Parcels', 'Projects', 'Both'];

export const TAB: ActiveTabType[] = ['Parcels', 'Projects'];

export const SurveyProductsBuild = [
  'Single Family',
  'Townhomes',
  'Condos/Apartments',
  'Commercial (Office)',
  'Commercial (Retail)',
  'Storage Units',
  'Industrial & Manufacturing',
  'Hospitality',
  'Hospitals',
  'Modular/Prefab/Manufactured Home Builder',
  'Ranches',
  'House or Building Flipping',
  'Residential Remodel Work',
  'Commercial Remodel Work',
  '55+ Communities',
  'Assisted Living',
  'Tiny Homes',
  'Cabins',
];

export const surveyAmountOfSingleFamily = [
  "Don't Develop SFD",
  '1-3',
  '4-15',
  '10-35',
  '30-55',
  '45-75',
  '65 or more',
];

export const surveyAmountOfTownhouse = ['2-30', '16-60', '50-90', '80+'];

export const surveyAmountOfTownhouseWithMore = [
  '2-30',
  '16-60',
  '50-90',
  '80 or more',
];

export const surveyAmountOfCondos = [
  '2-30',
  '31-60',
  '61-90',
  '90-120',
  '121-200',
  '201-500',
  '500 or more',
];

export const customBuilder = ['Yes', 'No'];

export const CITIESFORSEARCH = [
  { value: 'Adamsville', label: 'Adamsville' },
  { value: 'Beaver', label: 'Beaver' },
  { value: 'Greenville', label: 'Greenville' },
  { value: 'Manderfield', label: 'Manderfield' },
  { value: 'Milford', label: 'Milford' },
  { value: 'Minersville', label: 'Minersville' },
  { value: 'Sulphurdale', label: 'Sulphurdale' },
  { value: 'Bear River City', label: 'Bear River City' },
  { value: 'Beaverdam', label: 'Beaverdam' },
  { value: 'Bothwell', label: 'Bothwell' },
  { value: 'Brigham City', label: 'Brigham City' },
  { value: 'Collinston', label: 'Collinston' },
  { value: 'Corinne', label: 'Corinne' },
  { value: 'Deweyville', label: 'Deweyville' },
  { value: 'Elwood', label: 'Elwood' },
  { value: 'Fielding', label: 'Fielding' },
  { value: 'Garland', label: 'Garland' },
  { value: 'Honeyville', label: 'Honeyville' },
  { value: 'Mantua', label: 'Mantua' },
  { value: 'Penrose', label: 'Penrose' },
  { value: 'Perry', label: 'Perry' },
  { value: 'Plymouth', label: 'Plymouth' },
  { value: 'Portage', label: 'Portage' },
  { value: 'Riverside', label: 'Riverside' },
  { value: 'South Willard', label: 'South Willard' },
  { value: 'Thatcher', label: 'Thatcher' },
  { value: 'Tremonton', label: 'Tremonton' },
  { value: 'Wheelon', label: 'Wheelon' },
  { value: 'Willard', label: 'Willard' },
  { value: 'Blue Creek', label: 'Blue Creek' },
  { value: 'Cedar Creek', label: 'Cedar Creek' },
  { value: 'Etna', label: 'Etna' },
  { value: 'Grouse Creek', label: 'Grouse Creek' },
  { value: 'Howell', label: 'Howell' },
  { value: 'Lucin', label: 'Lucin' },
  { value: 'Lynn', label: 'Lynn' },
  { value: 'Park Valley', label: 'Park Valley' },
  { value: 'Rosette', label: 'Rosette' },
  { value: 'Snowville', label: 'Snowville' },
  { value: 'Yost', label: 'Yost' },
  { value: 'Amalga', label: 'Amalga' },
  { value: 'Avon', label: 'Avon' },
  { value: 'Benson', label: 'Benson' },
  { value: 'Cache', label: 'Cache' },
  { value: 'Clarkston', label: 'Clarkston' },
  { value: 'College Ward', label: 'College Ward' },
  { value: 'Cornish', label: 'Cornish' },
  { value: 'Cove', label: 'Cove' },
  { value: 'Hyde Park', label: 'Hyde Park' },
  { value: 'Hyrum', label: 'Hyrum' },
  { value: 'Lewiston', label: 'Lewiston' },
  { value: 'Logan', label: 'Logan' },
  { value: 'Mendon', label: 'Mendon' },
  { value: 'Millville', label: 'Millville' },
  { value: 'Newton', label: 'Newton' },
  { value: 'Nibley', label: 'Nibley' },
  { value: 'North Logan', label: 'North Logan' },
  { value: 'Paradise', label: 'Paradise' },
  { value: 'Peter', label: 'Peter' },
  { value: 'Providence', label: 'Providence' },
  { value: 'Richmond', label: 'Richmond' },
  { value: 'River Heights', label: 'River Heights' },
  { value: 'Smithfield', label: 'Smithfield' },
  { value: 'Trenton', label: 'Trenton' },
  { value: 'Wellsville', label: 'Wellsville' },
  { value: 'Carbonville', label: 'Carbonville' },
  { value: 'Castle Gate', label: 'Castle Gate' },
  { value: 'Clear Creek', label: 'Clear Creek' },
  { value: 'East Carbon', label: 'East Carbon' },
  { value: 'Helper', label: 'Helper' },
  { value: 'Hiawatha', label: 'Hiawatha' },
  { value: 'Kenilworth', label: 'Kenilworth' },
  { value: 'Price', label: 'Price' },
  { value: 'Scofield', label: 'Scofield' },
  { value: 'Spring Glen', label: 'Spring Glen' },
  { value: 'Sunnyside', label: 'Sunnyside' },
  { value: 'Wellington', label: 'Wellington' },
  { value: 'West Wood', label: 'West Wood' },
  { value: 'Dutch John', label: 'Dutch John' },
  { value: 'Flaming Gorge', label: 'Flaming Gorge' },
  { value: 'Manila', label: 'Manila' },
  { value: 'Red Canyon', label: 'Red Canyon' },
  { value: 'Clearfield', label: 'Clearfield' },
  { value: 'Clinton', label: 'Clinton' },
  { value: 'Fruit Heights', label: 'Fruit Heights' },
  { value: 'Kaysville', label: 'Kaysville' },
  { value: 'Layton', label: 'Layton' },
  { value: 'South Weber', label: 'South Weber' },
  { value: 'Sunset', label: 'Sunset' },
  { value: 'Syracuse', label: 'Syracuse' },
  { value: 'West Point', label: 'West Point' },
  { value: 'Bountiful', label: 'Bountiful' },
  { value: 'Centerville', label: 'Centerville' },
  { value: 'Farmington', label: 'Farmington' },
  { value: 'North Salt Lake', label: 'North Salt Lake' },
  { value: 'West Bountiful', label: 'West Bountiful' },
  { value: 'Woods Cross', label: 'Woods Cross' },
  { value: 'Ioka', label: 'Ioka' },
  { value: 'Monarch', label: 'Monarch' },
  { value: 'Myton', label: 'Myton' },
  { value: 'Neola', label: 'Neola' },
  { value: 'Roosevelt', label: 'Roosevelt' },
  { value: 'Altamont', label: 'Altamont' },
  { value: 'Altonah', label: 'Altonah' },
  { value: 'Bluebell', label: 'Bluebell' },
  { value: 'Boneta', label: 'Boneta' },
  { value: 'Bridgeland', label: 'Bridgeland' },
  { value: 'Duchesne', label: 'Duchesne' },
  { value: 'Fruitland', label: 'Fruitland' },
  { value: 'Hanna', label: 'Hanna' },
  { value: 'Mountain Home', label: 'Mountain Home' },
  { value: 'Strawberry', label: 'Strawberry' },
  { value: 'Tabiona', label: 'Tabiona' },
  { value: 'Talmage', label: 'Talmage' },
  { value: 'Castle Dale', label: 'Castle Dale' },
  { value: 'Clawson', label: 'Clawson' },
  { value: 'Cleveland', label: 'Cleveland' },
  { value: 'Elmo', label: 'Elmo' },
  { value: 'Emery', label: 'Emery' },
  { value: 'Ferron', label: 'Ferron' },
  { value: 'Huntington', label: 'Huntington' },
  { value: 'Mohrland', label: 'Mohrland' },
  { value: 'Moore', label: 'Moore' },
  { value: 'Orangeville', label: 'Orangeville' },
  { value: 'Victor', label: 'Victor' },
  { value: 'Antimony', label: 'Antimony' },
  { value: 'Bryce Canyon City', label: 'Bryce Canyon City' },
  { value: 'Cannonville', label: 'Cannonville' },
  { value: 'Escalante', label: 'Escalante' },
  { value: 'Hatch', label: 'Hatch' },
  { value: 'Henrieville', label: 'Henrieville' },
  { value: 'Osiris', label: 'Osiris' },
  { value: 'Panguitch', label: 'Panguitch' },
  { value: 'Spry', label: 'Spry' },
  { value: 'Tropic', label: 'Tropic' },
  { value: 'Widtsoe', label: 'Widtsoe' },
  { value: 'Ticaboo', label: 'Ticaboo' },
  { value: 'Boulder', label: 'Boulder' },
  { value: 'Castle Valley', label: 'Castle Valley' },
  { value: 'Cisco', label: 'Cisco' },
  { value: 'Green River', label: 'Green River' },
  { value: 'Moab', label: 'Moab' },
  { value: 'Thompson Springs', label: 'Thompson Springs' },
  { value: 'Brian Head', label: 'Brian Head' },
  { value: 'North View', label: 'North View' },
  { value: 'Cedar City', label: 'Cedar City' },
  { value: 'Cedar Highlands', label: 'Cedar Highlands' },
  { value: 'Enoch', label: 'Enoch' },
  { value: 'Hamiltons Fort', label: 'Hamiltons Fort' },
  { value: 'Kanarraville', label: 'Kanarraville' },
  { value: 'Paragonah', label: 'Paragonah' },
  { value: 'Parowan', label: 'Parowan' },
  { value: 'Summit', label: 'Summit' },
  { value: 'Beryl', label: 'Beryl' },
  { value: 'Beryl Junction', label: 'Beryl Junction' },
  { value: 'Lund', label: 'Lund' },
  { value: 'Modena', label: 'Modena' },
  { value: 'Newcastle', label: 'Newcastle' },
  { value: 'Old Irontown', label: 'Old Irontown' },
  { value: 'Partoun', label: 'Partoun' },
  { value: 'Trout Creek', label: 'Trout Creek' },
  { value: 'Levan', label: 'Levan' },
  { value: 'Mona', label: 'Mona' },
  { value: 'Nephi', label: 'Nephi' },
  { value: 'Rocky Ridge', label: 'Rocky Ridge' },
  { value: 'Eureka', label: 'Eureka' },
  { value: 'Mammoth', label: 'Mammoth' },
  { value: 'Bullfrog', label: 'Bullfrog' },
  { value: 'Alton', label: 'Alton' },
  { value: 'Big Water', label: 'Big Water' },
  { value: 'Duck Creek Village', label: 'Duck Creek Village' },
  { value: 'Glendale', label: 'Glendale' },
  { value: 'Kanab', label: 'Kanab' },
  { value: 'Mt Caramel Junction', label: 'Mt Caramel Junction' },
  { value: 'Mt Carmel', label: 'Mt Carmel' },
  { value: 'Orderville', label: 'Orderville' },
  { value: 'Cove Fort', label: 'Cove Fort' },
  { value: 'Eskdale', label: 'Eskdale' },
  { value: 'Gandy', label: 'Gandy' },
  { value: 'Garrison', label: 'Garrison' },
  { value: 'Abraham', label: 'Abraham' },
  { value: 'Delta', label: 'Delta' },
  { value: 'Deseret', label: 'Deseret' },
  { value: 'Fillmore', label: 'Fillmore' },
  { value: 'Flowell', label: 'Flowell' },
  { value: 'Hinckley', label: 'Hinckley' },
  { value: 'Holden', label: 'Holden' },
  { value: 'Kanosh', label: 'Kanosh' },
  { value: 'Leamington', label: 'Leamington' },
  { value: 'Lynndyl', label: 'Lynndyl' },
  { value: 'Meadow', label: 'Meadow' },
  { value: 'Oak City', label: 'Oak City' },
  { value: 'Oasis', label: 'Oasis' },
  { value: 'Scipio', label: 'Scipio' },
  { value: 'Sutherland', label: 'Sutherland' },
  { value: 'Croydon', label: 'Croydon' },
  { value: 'Morgan', label: 'Morgan' },
  { value: 'Mountain Green', label: 'Mountain Green' },
  { value: 'Peterson', label: 'Peterson' },
  { value: 'Angle', label: 'Angle' },
  { value: 'Circleville', label: 'Circleville' },
  { value: 'Greenwich', label: 'Greenwich' },
  { value: 'Junction', label: 'Junction' },
  { value: 'Kingston', label: 'Kingston' },
  { value: 'Marysvale', label: 'Marysvale' },
  { value: 'Garden', label: 'Garden' },
  { value: 'Garden City', label: 'Garden City' },
  { value: 'Laketown', label: 'Laketown' },
  { value: 'Randolph', label: 'Randolph' },
  { value: 'Woodruff', label: 'Woodruff' },
  { value: 'Magna', label: 'Magna' },
  { value: 'Cottonwood Heights', label: 'Cottonwood Heights' },
  { value: 'Emigration County', label: 'Emigration County' },
  { value: 'Holladay', label: 'Holladay' },
  { value: 'Kearns', label: 'Kearns' },
  { value: 'Midvale', label: 'Midvale' },
  { value: 'Millcreek', label: 'Millcreek' },
  { value: 'Murray', label: 'Murray' },
  { value: 'Salt Lake City', label: 'Salt Lake City' },
  { value: 'South Salt Lake', label: 'South Salt Lake' },
  { value: 'Taylorsville', label: 'Taylorsville' },
  { value: 'West Jordan', label: 'West Jordan' },
  { value: 'West Valley', label: 'West Valley' },
  { value: 'Brighton', label: 'Brighton' },
  { value: 'Bingham Canyon', label: 'Bingham Canyon' },
  { value: 'Bluffdale', label: 'Bluffdale' },
  { value: 'Copperton', label: 'Copperton' },
  { value: 'Draper', label: 'Draper' },
  { value: 'Granite', label: 'Granite' },
  { value: 'Herriman', label: 'Herriman' },
  { value: 'Riverton', label: 'Riverton' },
  { value: 'Sandy', label: 'Sandy' },
  { value: 'South Jordan', label: 'South Jordan' },
  { value: 'White City', label: 'White City' },
  { value: 'Fry Canyon', label: 'Fry Canyon' },
  { value: 'Halls Crossing', label: 'Halls Crossing' },
  { value: 'Hite', label: 'Hite' },
  { value: 'Spanish Valley', label: 'Spanish Valley' },
  { value: 'Aneth', label: 'Aneth' },
  { value: 'Blanding', label: 'Blanding' },
  { value: 'Bluff', label: 'Bluff' },
  { value: 'Halchita', label: 'Halchita' },
  { value: 'La Sal', label: 'La Sal' },
  { value: 'Mexican Hat', label: 'Mexican Hat' },
  { value: 'Montezuma Creek', label: 'Montezuma Creek' },
  { value: 'Monticello', label: 'Monticello' },
  { value: 'Navajo Mountain', label: 'Navajo Mountain' },
  { value: 'Oljato-Monument Valley', label: 'Oljato-Monument Valley' },
  { value: 'Tselekai Dezza', label: 'Tselekai Dezza' },
  { value: 'White Mesa', label: 'White Mesa' },
  { value: 'Axtell', label: 'Axtell' },
  { value: 'Centerfield', label: 'Centerfield' },
  { value: 'Chester', label: 'Chester' },
  { value: 'Ephraim', label: 'Ephraim' },
  { value: 'Fairview', label: 'Fairview' },
  { value: 'Fayette', label: 'Fayette' },
  { value: 'Fountain Green', label: 'Fountain Green' },
  { value: 'Freedom', label: 'Freedom' },
  { value: 'Gunnison', label: 'Gunnison' },
  { value: 'Indianola', label: 'Indianola' },
  { value: 'Manti', label: 'Manti' },
  { value: 'Mayfield', label: 'Mayfield' },
  { value: 'Milburn', label: 'Milburn' },
  { value: 'Moroni', label: 'Moroni' },
  { value: 'Mt Pleasant', label: 'Mt Pleasant' },
  { value: 'Spring City', label: 'Spring City' },
  { value: 'Sterling', label: 'Sterling' },
  { value: 'Wales', label: 'Wales' },
  { value: 'Annabella', label: 'Annabella' },
  { value: 'Aurora', label: 'Aurora' },
  { value: 'Austin', label: 'Austin' },
  { value: 'Burrville', label: 'Burrville' },
  { value: 'Central Valley', label: 'Central Valley' },
  { value: 'Elsinore', label: 'Elsinore' },
  { value: 'Glenwood', label: 'Glenwood' },
  { value: 'Joseph', label: 'Joseph' },
  { value: 'Koosharem', label: 'Koosharem' },
  { value: 'Monroe', label: 'Monroe' },
  { value: 'Redmond', label: 'Redmond' },
  { value: 'Richfield', label: 'Richfield' },
  { value: 'Salina', label: 'Salina' },
  { value: 'Sevier', label: 'Sevier' },
  { value: 'Sigurd', label: 'Sigurd' },
  { value: 'Venice', label: 'Venice' },
  { value: 'Whipup', label: 'Whipup' },
  { value: 'Coalville', label: 'Coalville' },
  { value: 'Echo', label: 'Echo' },
  { value: 'Francis', label: 'Francis' },
  { value: 'Hoytsville', label: 'Hoytsville' },
  { value: 'Kamas', label: 'Kamas' },
  { value: 'Marion', label: 'Marion' },
  { value: 'Oakley', label: 'Oakley' },
  { value: 'Peoa', label: 'Peoa' },
  { value: 'Samak', label: 'Samak' },
  { value: 'Wanship', label: 'Wanship' },
  { value: 'Woodland', label: 'Woodland' },
  { value: 'Henefer', label: 'Henefer' },
  { value: 'Alta', label: 'Alta' },
  { value: 'Deer Mountain', label: 'Deer Mountain' },
  { value: 'Hideout', label: 'Hideout' },
  { value: 'Keetley', label: 'Keetley' },
  { value: 'Kimball Junction', label: 'Kimball Junction' },
  { value: 'Park City', label: 'Park City' },
  { value: 'Silver Summit', label: 'Silver Summit' },
  { value: 'Snyderville', label: 'Snyderville' },
  { value: 'Summit Park', label: 'Summit Park' },
  { value: 'Faust', label: 'Faust' },
  { value: 'Rush Valley', label: 'Rush Valley' },
  { value: 'Vernon', label: 'Vernon' },
  { value: 'Mills Junction', label: 'Mills Junction' },
  { value: 'Stansbury Park', label: 'Stansbury Park' },
  { value: 'Burmester', label: 'Burmester' },
  { value: 'Erda', label: 'Erda' },
  { value: 'Grantsville', label: 'Grantsville' },
  { value: 'Lincoln', label: 'Lincoln' },
  { value: 'Stockton', label: 'Stockton' },
  { value: 'Tooele', label: 'Tooele' },
  { value: 'Aragonite', label: 'Aragonite' },
  { value: 'Dugway', label: 'Dugway' },
  { value: 'Gold Hill', label: 'Gold Hill' },
  { value: 'Ibapah', label: 'Ibapah' },
  { value: 'Skull Valley', label: 'Skull Valley' },
  { value: 'Terra', label: 'Terra' },
  { value: 'Wendover', label: 'Wendover' },
  { value: 'Ballard', label: 'Ballard' },
  { value: 'Fort Duchesne', label: 'Fort Duchesne' },
  { value: 'Gusher', label: 'Gusher' },
  { value: 'Leeton', label: 'Leeton' },
  { value: 'Randlett', label: 'Randlett' },
  { value: 'Tridell', label: 'Tridell' },
  { value: 'Whiterocks', label: 'Whiterocks' },
  { value: 'Lapoint', label: 'Lapoint' },
  { value: 'Ouray', label: 'Ouray' },
  { value: 'Bonanza', label: 'Bonanza' },
  { value: 'Jensen', label: 'Jensen' },
  { value: 'Maeser', label: 'Maeser' },
  { value: 'Naples City', label: 'Naples City' },
  { value: 'Vernal', label: 'Vernal' },
  { value: 'Cedar Fort', label: 'Cedar Fort' },
  { value: 'Cedar Valley', label: 'Cedar Valley' },
  { value: 'Fairfield', label: 'Fairfield' },
  { value: 'Eagle Mountain', label: 'Eagle Mountain' },
  { value: 'Alpine', label: 'Alpine' },
  { value: 'American Fork', label: 'American Fork' },
  { value: 'Cedar Hills', label: 'Cedar Hills' },
  { value: 'Highland', label: 'Highland' },
  { value: 'Lehi', label: 'Lehi' },
  { value: 'Lindon', label: 'Lindon' },
  { value: 'Orem', label: 'Orem' },
  { value: 'Pleasant Grove', label: 'Pleasant Grove' },
  { value: 'Provo', label: 'Provo' },
  { value: 'Vineyard', label: 'Vineyard' },
  { value: 'Benjamin', label: 'Benjamin' },
  { value: 'Elk Ridge', label: 'Elk Ridge' },
  { value: 'Lake Shore', label: 'Lake Shore' },
  { value: 'Mapleton', label: 'Mapleton' },
  { value: 'Palmyra', label: 'Palmyra' },
  { value: 'Payson', label: 'Payson' },
  { value: 'Salem', label: 'Salem' },
  { value: 'Santaquin', label: 'Santaquin' },
  { value: 'Spanish Fork', label: 'Spanish Fork' },
  { value: 'Spring Lake', label: 'Spring Lake' },
  { value: 'Springville', label: 'Springville' },
  { value: 'West Mountain', label: 'West Mountain' },
  { value: 'Woodland Hills', label: 'Woodland Hills' },
  { value: 'Dividend', label: 'Dividend' },
  { value: 'Elberta', label: 'Elberta' },
  { value: 'Genola', label: 'Genola' },
  { value: 'Goshen', label: 'Goshen' },
  { value: 'Mosida', label: 'Mosida' },
  { value: 'Birdseye', label: 'Birdseye' },
  { value: 'Soldier Summit', label: 'Soldier Summit' },
  { value: 'Thistle', label: 'Thistle' },
  { value: 'Tucker', label: 'Tucker' },
  { value: 'Saratoga Springs', label: 'Saratoga Springs' },
  { value: 'Charleston', label: 'Charleston' },
  { value: 'Daniel', label: 'Daniel' },
  { value: 'Heber', label: 'Heber' },
  { value: 'Independence', label: 'Independence' },
  { value: 'Interlaken', label: 'Interlaken' },
  { value: 'Midway', label: 'Midway' },
  { value: 'Timber Lakes', label: 'Timber Lakes' },
  { value: 'Wallsburg', label: 'Wallsburg' },
  { value: 'New Harmony', label: 'New Harmony' },
  { value: 'Apple Valley', label: 'Apple Valley' },
  { value: 'Grafton', label: 'Grafton' },
  { value: 'Hildale', label: 'Hildale' },
  { value: 'Rockville', label: 'Rockville' },
  { value: 'Springdale', label: 'Springdale' },
  { value: 'Virgin', label: 'Virgin' },
  { value: 'Hurricane', label: 'Hurricane' },
  { value: 'Ivins', label: 'Ivins' },
  { value: 'La Verkin', label: 'La Verkin' },
  { value: 'Leeds', label: 'Leeds' },
  { value: 'Pintura', label: 'Pintura' },
  { value: 'Santa Clara', label: 'Santa Clara' },
  { value: 'St George', label: 'St George' },
  { value: 'Toquerville', label: 'Toquerville' },
  { value: 'Washington', label: 'Washington' },
  { value: 'Central', label: 'Central' },
  { value: 'Dammeron Valley', label: 'Dammeron Valley' },
  { value: 'Gunlock', label: 'Gunlock' },
  { value: 'Montoqua', label: 'Montoqua' },
  { value: 'Pine Valley', label: 'Pine Valley' },
  { value: 'Shem', label: 'Shem' },
  { value: 'Shivwits', label: 'Shivwits' },
  { value: 'Veyo', label: 'Veyo' },
  { value: 'Enterprise', label: 'Enterprise' },
  { value: 'Bicknell', label: 'Bicknell' },
  { value: 'Caineville', label: 'Caineville' },
  { value: 'Fremont', label: 'Fremont' },
  { value: 'Fruita', label: 'Fruita' },
  { value: 'Grover', label: 'Grover' },
  { value: 'Hanksville', label: 'Hanksville' },
  { value: 'Loa', label: 'Loa' },
  { value: 'Lyman', label: 'Lyman' },
  { value: 'Teasdale', label: 'Teasdale' },
  { value: 'Torrey', label: 'Torrey' },
  { value: 'Eden', label: 'Eden' },
  { value: 'Huntsville', label: 'Huntsville' },
  { value: 'Liberty', label: 'Liberty' },
  { value: 'Powder Mountain', label: 'Powder Mountain' },
  { value: 'Wolf Creek', label: 'Wolf Creek' },
  { value: 'Farr West', label: 'Farr West' },
  { value: 'Harrisville', label: 'Harrisville' },
  { value: 'Hill Air Force Base', label: 'Hill Air Force Base' },
  { value: 'Hooper', label: 'Hooper' },
  { value: 'Marriot-Slaterville', label: 'Marriot-Slaterville' },
  { value: 'North Ogden', label: 'North Ogden' },
  { value: 'Ogden', label: 'Ogden' },
  { value: 'Plain City', label: 'Plain City' },
  { value: 'Pleasant View', label: 'Pleasant View' },
  { value: 'Riverdale', label: 'Riverdale' },
  { value: 'Roy', label: 'Roy' },
  { value: 'South Ogden', label: 'South Ogden' },
  { value: 'Taylor', label: 'Taylor' },
  { value: 'Uintah', label: 'Uintah' },
  { value: 'Warren', label: 'Warren' },
  { value: 'Washington Terrace', label: 'Washington Terrace' },
  { value: 'West Haven', label: 'West Haven' },
  { value: 'West Weber', label: 'West Weber' },
];

export const GENERALZONINGQUESTION = [
  'Single Family',
  'Townhouses',
  'Apartments/Condos',
  'Commercial Office',
  'Commercial Retail',
  'Agriculture',
  'Industrial & Manufacturing',
  'Storage Units',
];

export const TYPESTYLEOFHOME = [
  'Rambler',
  'Two Story',
  'Townhouse',
  'Condo',
  'Modern',
  'Cabin',
  'Patio Home',
  'Mobile Home',
];

export const REALESTATESPECIALTY = [
  'Residential',
  'Commercial',
  'Land',
  'Investment Property',
  'Flipping',
  'Ranches',
  'Residential Property Management',
  'Commercial Property Management',
];

export const REALESTATELICENSETYPE = [
  'Broker',
  'Associate Broker',
  'Real Estate Agent',
];

export const PROJECTSURVEYSTATUS = [
  'Concept',
  'Seeking Preliminary from Planning Commission',
  'Seeking Preliminary from City Council',
  'Prelim Rejected by City Council',
  'Seeking Final',
  'Final Rejected by City Council',
  'For Sale',
  'Abandoned',
  'Papered',
  'Rezone Accepted',
  'Coming Soon',
  'For Rent',
  'Human Check Needed',
];

export const LOTSIZE = [
  'Apartments/Condos',
  'Townhomes',
  '.02-.08 Acres',
  '.08-.18 Acres',
  'Quarter Acres (.18-.28)',
  'Third Acres (.28-.38)',
  'High Third Acres (.38-.48)',
  'Half Acres (.42-.7)',
  'Acres (.7-1.2)',
  'Acres+ (1.2 +)',
  'Commercial',
];

export const PROJECTTYPE = [
  'Commercial Office',
  'Commercial Retail',
  'Resort',
  'Hospitals',
  'Single Family Residential',
  'Town Homes',
  'Apartments/Condos',
  'Storage Units',
  'Industrial & Manufacturing',
  '55+ Communities',
  'Mobile/Modular/Prefab Manufacturer',
  'Assisted Living',
  'Tiny Houses',
  'Ranches',
  'Cabins',
  'Hospitality',
];

export const PRODUCTSCLIENTBUILD = [
  'Condos/Apartments',
  'Townhomes',
  'Single Family',
  'Multi Unit (Duplex, Triplex, etc)',
  'Land',
  'Commercial (Office)',
  'Commercial (Retail)',
  'Storage Units',
  'Industrial & Manufacturing',
  'Mobile Parks',
  'House Flipping',
  'Building Flipping',
  'Hospitality',
  'Resort/Airbnb',
  '55+ Communities',
  'Assisted Living',
  'Student Housing',
  'Hospitals',
];

export const REALESTATEPRODUCTSCLIENTDEVELOP = [
  'Apartments/Condos',
  'Townhomes',
  'Single Family',
  'Multi Unit (Duplex, Triplex, etc)',
  'Land',
  'Commercial Office',
  'Commercial Retail',
  'Storage Units',
  'Industrial & Manufacturing',
  'Mobile Parks',
  'House Flipping',
  'Building Flipping',
  'Hospitality',
  'Resort/Airbnb',
  '55+ Communities',
  'Assisted Living',
  'Student Housing',
  'Hospitals',
];

export const CLIENTPAPERRESIDENTIAL = [
  'Both',
  'Residential Lots',
  'Commercial Pads',
  'Neither',
];

export const residentialStyleOfLots = [
  'Apartments/Condos 2-30',
  'Apartments/Condos 31-60',
  'Apartments/Condos 61-90',
  'Apartments/Condos 91-120',
  'Apartments/Condos 121-200',
  'Apartments/Condos 201-500',
  'Apartments/Condos 500+',
  'Townhouse Pads 2-30',
  'Townhouse Pads 16-60',
  'Townhouse Pads 50-90',
  'Townhouse Pads 81+',
  'Single Family 1-3',
  'Single Family 4-15',
  'Single Family 10-35',
  'Single Family 30-55',
  'Single Family 45-75',
  'Single Family 65+',
  'Mobile Parks',
];

export const commercialStyleOfLots = [
  'Commercial Office',
  'Commercial Retail',
  'Storage Units',
  'Industrial & Manufacturing',
  'Hospitality',
  'Assisted Living',
  'Hospitals',
];

export const apartmentCondosLots = [
  'Apartments/Condos 2-30',
  'Apartments/Condos 31-60',
  'Apartments/Condos 61-90',
  'Apartments/Condos 91-120',
  'Apartments/Condos 121-200',
  'Apartments/Condos 201-500',
  'Apartments/Condos 500+',
];

export const singleFamilyLots = [
  'Single Family 1-3',
  'Single Family 4-15',
  'Single Family 10-35',
  'Single Family 30-55',
  'Single Family 45-75',
  'Single Family 65+',
];

export const townHousePadsLots = [
  'Townhouse Pads 2-30',
  'Townhouse Pads 16-60',
  'Townhouse Pads 50-90',
  'Townhouse Pads 81+',
];

export enum InvestmentRange {
  'noMin' = 'No Minimum',
  'nomin' = 'No minimum',
  '100k' = '$100,000 or less',
  '200k' = '$200,000',
  '300k' = '$300,000',
  '400k' = '$400,000',
  '500k' = '$500,000',
  '750k' = '$750,000',
  '1m' = '$1,000,000',
  '1.5m' = '$1,500,000',
  '2m' = '$2,000,000',
  '2.5m' = '$2,500,000',
  '3m' = '$3,000,000',
  '3.5m' = '$3,500,000',
  '4m' = '$4,000,000',
  '5m' = '$5,000,000',
  '6m' = '$6,000,000',
  '7m' = '$7,000,000',
  '8m' = '$8,000,000',
  '9m' = '$9,000,000',
  '10m' = '$10,000,000',
  '11m' = '$11,000,000',
  '12m' = '$12,000,000',
  '13m' = '$13,000,000',
  '14m' = '$14,000,000',
  '15-20m' = '$15-20 Mil',
  '20-25m' = '$20-25 Mil',
  '25-30m' = '$25-30 Mil',
  '30-50m' = '$30-50 Mil',
  '50-100m' = '$50-100 Mil',
  '100-150m' = '$100-150 Mil',
  '150-250m' = '$150-250 Mil',
  '250-500m' = '$250-500 Mil',
  '500m+' = '$500 Mil+',
  '500m +' = '$500 Mil +',
}

export enum Condos {
  '2-30' = 'Apartments/Condos 2-30',
  '31-60' = 'Apartments/Condos 31-60',
  '61-90' = 'Apartments/Condos 61-90',
  '91-120' = 'Apartments/Condos 91-120',
  '121-200' = 'Apartments/Condos 121-200',
  '201-500' = 'Apartments/Condos 201-500',
  '500+' = 'Apartments/Condos 500+',
}
// Enum defining different ranges of condominium units, categorized by the number of units.

export enum SingleFamily {
  '1-3' = 'Single Family 1-3',
  '4-15' = 'Single Family 4-15',
  '10-35' = 'Single Family 10-35',
  '30-55' = 'Single Family 30-55',
  '45-75' = 'Single Family 45-75',
  '65+' = 'Single Family 65+',
}
// Enum defining different ranges of single-family homes, categorized by the number of homes.

export enum TownHouse {
  '2-30' = 'Townhouse Pads 2-30',
  '16-60' = 'Townhouse Pads 16-60',
  '50-90' = 'Townhouse Pads 50-90',
  '81+' = 'Townhouse Pads 81+',
}
// Enum defining different ranges of townhouse units, categorized by the number of units.

export enum LotsProduct {
  'condos' = 'Apartments/Condos',
  'townhomes' = 'Townhomes',
  'townhouses' = 'Townhouses',
  'singleFamily' = 'Single Family',
  'multiUnit' = 'Multi Unit (Duplex, Triplex, etc)',
  'land' = 'Land',
  'commercialOffice' = 'Commercial Office',
  'commercialRetail' = 'Commercial Retail',
  'storageUnits' = 'Storage Units',
  'industrialManufacturing' = 'Industrial & Manufacturing',
  'mobileParks' = 'Mobile Parks',
  'mortgages' = 'Mortgages',
  'notes' = 'Notes',
  'ranches' = 'Ranches',
  'houseFlipping' = 'House Flipping',
  'buildingFlipping' = 'Building Flipping',
  'distressedProperties' = 'Distressed Properties',
  'hospitality' = 'Hospitality',
  'resortAirbnb' = 'Resort/Airbnb',
  'communities55more' = '55+ Communities',
  'assistedLiving' = 'Assisted Living',
  'studentHousing' = 'Student Housing',
  'hospitals' = 'Hospitals',
  'commercialBuildingFlipper' = 'Commercial Building Flipper',
  'agriculture' = 'Agriculture',
  'mobileHome' = 'Mobile Home',
  'resort' = 'Resort',
  'resorts' = 'Resorts',
}

export const LEADSOURCE = [
  'Roor',
  'Mailer',
  'Survey',
  'Social Media/Digital Ad',
  'Referal/Personal Connection',
  'Other',
];

export const YESORNO = ['Yes', 'No'];

export const YESNOOTHER = ['Yes', 'No', "I don't know"];

export const DESIREDPRODUCT = [
  "Don't have a preference",
  'Townhomes',
  'Apartments',
  'Single Family Homes',
  'Mobile Homes',
  'Other Commercial',
  'Commercial',
];

export const SURROUNDINGNEIGHBORHOOD = [
  'Not touching developed area',
  'Touching developed area on 1-2 sides',
  'Touching developed area on 3-4 sides',
];

export const TYPOGRAPHY = [
  'Not touching developed area',
  'Touching developed area on 1-2 sides',
  'Touching developed area on 3-4 sides',
];

export const POWERLINES = [
  'On Property',
  'Within 100 Feet of Property Boundary',
  'More than 100 Feet from Property Boundary',
];

export const RAILROADTRACKS = [
  'On Property',
  'Within 300 Feet of Property Boundary',
  'More than 300 Feet from Property Boundary',
];

export const SURROUNDINGPRODUCT = [
  'SFD',
  'Town',
  'Condo/apartments',
  'Land',
  'Commercial Office',
  'Commercial Retail',
  'Commercial Industrial/Manufacturing',
  'Commercial Hospitality',
];

export const INCORPORATED = ['Incorporated', 'Unincorporated'];
