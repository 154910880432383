import type { MenuProps } from 'antd';
import { Layout, Menu, Drawer } from 'antd';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDisclosure } from 'utils';
import { useLocation } from 'react-router-dom';
import { LogOut } from 'app/baseQuery';
import { setAccessToken } from '../app/services';
import ProfileSearchCheckboxGroup from 'views/Profile/ProfileSearchCheckboxGroup';

const { Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const itemsUser: MenuItem[] = [
  getItem(<Link to="/profile">Profile</Link>, '/profile'),
  getItem(<Link to="/network">Network</Link>, '/network'),
  // getItem(<Link to="/client">Client</Link>, '/client'),
  getItem(<Link to="/map">Opportunities</Link>, '/map'),
  // getItem(<Link to="/projects">Projects</Link>, '/projects'),
  // getItem(<Link to="#">Saved Searches</Link>, '5'),
];

const itemsAdmin: MenuItem[] = [
  getItem(<Link to="/admin">Admin Panel</Link>, '/admin'),
];

interface ILayout {
  children: any;
  menuShow: boolean;
  isDrawerOpen?: boolean
  setSurveyid?: (value: string) => void;
  onSurveyTakerChange?: (value: string) => void;
  onClientCodeChange?: (value: string) => void;
}

const SiteLayout: React.FC<ILayout> = ({ children, menuShow, setSurveyid,
  onSurveyTakerChange, onClientCodeChange, isDrawerOpen }) => {
  const { isOpen: isMainFiltersDrawerOpen, close: closeDrawer, open: openDrawer } =
    useDisclosure(menuShow);
  const getDrawerWidth = () => (window.innerWidth < 768 || !isMainFiltersDrawerOpen ? '100%' :  'calc(100vw - 222px)');

  const location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  const loginUser =
    localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user') as string);

  const toggleMainFiltersDrawer = () => {
    if (isMainFiltersDrawerOpen) {
      closeDrawer()
    } else {
      openDrawer()
    }
  }
  useEffect(() => {
    if (isDrawerOpen !== undefined) {
      if (isDrawerOpen) {
        openDrawer()
      } else {
        closeDrawer()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen])
  return (
    <div className="main-navbar">
      <div
        className="leftDrawer"
        style={{
          width: isMainFiltersDrawerOpen ? '220px' : '0',
          height: isMainFiltersDrawerOpen ? '100vh' : '0',
        }}
      >
        {/* Left side Open drawer button */}
        {!isMainFiltersDrawerOpen && (
          <CaretRightOutlined
            className="open-drawer-btn left-drawer"
            onClick={toggleMainFiltersDrawer}
          />
        )}
        <Drawer
          className={`menu-drawer ${!isMainFiltersDrawerOpen && 'close-menu'}`}
          placement="left"
          onClose={toggleMainFiltersDrawer}
          open={isMainFiltersDrawerOpen}
          closeIcon={<CloseOutlined />}
          push={false}
          mask={false}
          width={250}
          closable={false}
          zIndex={1001}
        >
          {isMainFiltersDrawerOpen ? (
            <CaretLeftOutlined
              className="close-drawer-btn left-drawer drawer-menu-1"
              onClick={toggleMainFiltersDrawer}
            />
          ) : null}
          <div className="logo">
            <Link to="/profile">
              <img className="naxxa-logo" src={'logo.png'} alt="" />
            </Link>
          </div>

          <ProfileSearchCheckboxGroup setSurveyid={setSurveyid}
            onSurveyTakerChange={onSurveyTakerChange}
            onClientCodeChange={onClientCodeChange} />
          {/* {location?.pathname.includes('profile') ?
            <ProfileSearchCheckboxGroup setSurveyid={setSurveyid}
              onSurveyTakerChange={onSurveyTakerChange}
              onClientCodeChange={onClientCodeChange} /> :
            <SearchCheckboxGroup onSurveyTakerChange={onSurveyTakerChange} 
            setSurveyid={setSurveyid} />
          } */}

          <Menu
            theme="dark"
            defaultSelectedKeys={[location.pathname]}
            mode="inline"
            items={
              loginUser.role === 'admin'
                ? itemsUser.concat(itemsAdmin)
                : loginUser.role === 'user' ? itemsUser : []
            }
          />
          <div
            onClick={async () => {
              LogOut();
              dispatch(setAccessToken(null));
              history.push('/login');
            }}
            className="logout-btn"
          >
            Logout
          </div>
        </Drawer>
      </div>

      <div
        className="site-layout"
        style={{
          width: getDrawerWidth(),
          position: 'relative',
        }}
      >
        <Content>
          <div className="site-layout-background">{children}</div>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}></Footer> */}
      </div>
    </div>
  );
};

export default SiteLayout;
